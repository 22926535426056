import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
  document.title = 'Error | X-Cloud School'
  return (
    <main className='errorPage'>
      <h1>Oops! Something went wrong.</h1>
      <p>The page you are looking for does not exist. It might have been moved or deleted.</p>
      <Link to='/'>Go home</Link>
    </main>
  )
}
