import { createSlice } from '@reduxjs/toolkit'

const staff = createSlice({
  name: 'staff',
  initialState: {
    administration: null,
    teachers: null,
    workers: null,
  },
  reducers: {
    administration(state, action) {
      state.administration = action.payload
    },
    teachers(state, action) {
      state.teachers = action.payload
    },
    workers(state, action) {
      state.workers = action.payload
    },
  },
})

export const { workers, teachers, administration } = staff.actions
export default staff.reducer
