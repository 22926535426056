import useAjax from '../hooks/useAjax'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { userAdd, userLogin } from '../store/slices/login'

import '../styles/login.scss'

export default function Login() {
  const ajax = useAjax()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  //Initialization form manager
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', reValidateMode: 'onChange' })

  //Initialization state manager
  const [errorResponse, setErrorResponse] = useState('')

  //Request for login in admin panel
  const onSubmit = data => {
    const promise = new Promise((resolve, reject) => {
      ajax.request({ email: data.loginEmail, password: data.loginPassword }, 'login', 'post', resolve, reject)
    })
    promise.then(data => {
      dispatch(userAdd(data.data))
      dispatch(userLogin(true))
    })
    promise.catch(data => {
      setErrorResponse('* ' + t(`${data}`))
    })
  }

  return (
    <main className='login'>
      <div>
        <h1>SafeBit business</h1>
        <h2>Course module</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            Email
            <input
              {...register('loginEmail', {
                required: 'This field is required',
                minLength: {
                  value: 6,
                  message: 'Min length 6',
                },
                maxLength: {
                  value: 100,
                  message: 'Max length 100',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address',
                },
              })}
            />
          </label>
          <span className='errorResponse'>{errors.loginEmail && errors.loginEmail.message}</span>
          <label>
            Password
            <input
              type='password'
              {...register('loginPassword', {
                required: 'This field is required',
                minLength: {
                  value: 6,
                  message: 'Min length 6',
                },
                maxLength: {
                  value: 100,
                  message: 'Max length 100',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.loginPassword && errors.loginPassword.message}
            {errorResponse && errorResponse}
          </span>
          <input type='submit' className='sendButton' value='LOGIN' disabled={!isValid} />
        </form>
      </div>
    </main>
  )
}

// Get current user
// axios.get('http://172.32.4.155:9999/api/user',  { "Authorization": `Bearer ${response.data.token}`,"Content-Type": "application/json"})
// .then(response => {
//   console.log(response)
// })
// .catch(error => {
//   console.log(error)
// })

// Registration new user
// useEffect(() => {
//   axios
//     .post('http://127.0.0.1:8000/api/register', {
//       name: 'Michael Three',
//       email: 'e@x-cloud.biz',
//       password: '123456',
//       password_confirmation: '123456',
//     })
//     .then(response => {
//       console.log(response)
//     })
//     .catch(error => {
//       console.log(error)
//     })
// }, [])
