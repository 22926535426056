import axios from 'axios'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { userLogin } from '../store/slices/login'

export default function useAjax() {
  const dispatch = useDispatch()
  return {
    request: useCallback(
      (body, url, method, resolve, reject) => {
        axios({
          method: method,
          url: 'https://api-learn.x-cloud.info/api/' + url,
          data: body,
          headers: {
            Authorization: `Bearer ${localStorage.userToken}`,
            'Content-Type': 'application/json',
          },
        }).then(response => {
          const dataResponse = response.data.responseCode.responseCode
          if (dataResponse === '1') {
            resolve(response.data)
          } else if (dataResponse === '105' || dataResponse === '104' || dataResponse === '103') {
            dispatch(userLogin(false))
            localStorage.removeItem('userToken')
            localStorage.removeItem('userLogin')
          } else {
            reject(response.data.responseCode)
          }
        })
      },
      [dispatch]
    ),
  }
}
