import React from 'react'

import '../styles/loading.scss'

export default function Loading() {
  return (
    <div className='loadingContainer'>
      <div className='loading'>
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  )
}
