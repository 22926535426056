import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import one from '../images/1.PNG'
import two from '../images/2.PNG'

export default function Dashboard() {
  const { t } = useTranslation()

  document.title = 'Dashboard | ' + t('name')

  const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '250px',
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  }

  const styleTwo = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '400px',
    textAlign: 'center',
    marginTop: '25px',
    marginBottom: '25px',
  }

  const main = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  }

  return (
    <div style={main}>
      <div style={style}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={one} alt='' />
      </div>
      <div style={style}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={one} alt='' />
      </div>
      <div style={styleTwo}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={two} alt='' />
      </div>
      <div style={styleTwo}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={two} alt='' />
      </div>
      <div style={style}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={one} alt='' />
      </div>
      <div style={style}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={one} alt='' />
      </div>
      <div style={styleTwo}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={two} alt='' />
      </div>
      <div style={styleTwo}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={two} alt='' />
      </div>
      <div style={style}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={one} alt='' />
      </div>
      <div style={style}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={one} alt='' />
      </div>
      <div style={styleTwo}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={two} alt='' />
      </div>
      <div style={styleTwo}>
        <h2>Lorem, ipsum dolor.</h2>
        <img src={two} alt='' />
      </div>
    </div>
  )
}
