import { createSlice } from '@reduxjs/toolkit'

const education = createSlice({
  name: 'education',
  initialState: {
    cycle: false,
    curriculum: false,
    course: false,
  },
  reducers: {
    cycleAdd(state, action) {
      state.cycle = action.payload
    },
    curriculumAdd(state, action) {
      state.curriculum = action.payload
    },
    courseAdd(state, action) {
      state.course = action.payload
    },
  },
})

export const { cycleAdd, curriculumAdd, courseAdd } = education.actions
export default education.reducer
