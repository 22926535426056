import React, { useEffect } from "react";

import "../styles/modal.scss";

export default function Modal({ active, setActive, children }) {
  useEffect(() => {
    const mouseUp = (e) => {
      if ((e.type === "mousedown") & (e.target.className === "modal active")) {
        setActive(false);
      }
    };
    document.addEventListener("mousedown", mouseUp);
    return function () {
      document.removeEventListener("mousedown", mouseUp);
    };
  });

  return (
    <div className={active ? "modal active" : "modal"}>
      <div className="content" onClick={(e) => e.stopPropagation()}>
        <div className="main">
          <span
            className="exit"
            onClick={() => {
              setActive(false);
            }}
          >
            x
          </span>
          {children}
        </div>
      </div>
    </div>
  );
}
