import Rooms from '../components/rooms'
import useAjax from '../hooks/useAjax'
import Modal from '../components/modal'
import { useForm } from 'react-hook-form'
import Loading from '../components/loading'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { branchesAdd } from '../store/slices/branches'

import '../styles/branches.scss'

import editIcon from '../images/edit.png'
import deleteIcon from '../images/delete.png'

export default function Branches() {
  //Translate initialization
  const { t } = useTranslation()
  document.title = 'Branches | ' + t('name')

  //State manager initialization
  const ajax = useAjax()
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [idRooms, setIdRooms] = useState(null)
  const [titleBranch, setTitleBranch] = useState(null)
  const [currentId, setCurrentId] = useState(null)
  const [modalRooms, setModalRooms] = useState(false)
  const [currentForm, setCurrentForm] = useState(null)
  const allBranches = useSelector(state => state.branchesReducer.branches)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { editValues: { name: '', type: '' } },
    reValidateMode: 'onChange',
  })

  //Get all branches request
  useEffect(() => {
    if (!allBranches) {
      const promise = new Promise(resolve => {
        ajax.request(null, 'manager/branches/getAll', 'post', resolve)
      })
      promise.then(data => {
        dispatch(branchesAdd(data.data))
      })
    }
  }, [allBranches, ajax, dispatch])

  const getRooms = event => {
    setIdRooms(event.target.closest('.item').querySelector('.itemButtons button').dataset.value)
    setTitleBranch(event.target.closest('.item').querySelector('.titleBranch').textContent)
    setModalRooms(true)
  }

  const branches = allBranches ? (
    Object.values(allBranches).map(child => {
      return (
        <div className='item' key={child.id} onClick={event => getRooms(event)}>
          <p title={child.name} className='titleBranch'>
            {child.name}
          </p>
          <p>{child.type === '1' ? 'Man' : 'Woman'}</p>
          <p>{child.code}</p>
          <div className='itemButtons'>
            <button
              data-value={child.id}
              onClick={function (event) {
                event.stopPropagation()
                openModal(event)
              }}>
              <img src={editIcon} alt='edit icon' data-value={child.id} />
            </button>
            <button
              data-value={child.id}
              onClick={function (event) {
                event.stopPropagation()
                deleteBranch(event)
              }}>
              <img src={deleteIcon} alt='delete icon' data-value={child.id} />
            </button>
          </div>
        </div>
      )
    })
  ) : (
    <Loading />
  )

  //Request for add new Branch
  const onSubmitSave = data => {
    const body = data.editValues
    if (currentForm === 'add') {
      const promise = new Promise(resolve => {
        ajax.request(body, 'manager/branches/add', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allBranches.slice()
        newArray.unshift(data.data)
        dispatch(branchesAdd(newArray))
        setModal(false)
      })
    } else {
      const promise = new Promise(resolve => {
        ajax.request({ ...body, id: currentId }, 'manager/branches/update', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allBranches.slice()
        const currentEdit = newArray.indexOf(newArray.find(child => child.id === Number(currentId)))
        newArray[currentEdit] = { ...body, id: currentId }
        dispatch(branchesAdd(newArray))
        setModal(false)
      })
    }
  }

  //Request for delete branch
  const deleteBranch = event => {
    const id = event.target.dataset.value
    const promise = new Promise(resolve => {
      ajax.request({ id: id }, 'manager/branches/delete', 'post', resolve)
    })
    promise.then(data => {
      const deleteArray = allBranches.slice()
      const indexDelete = deleteArray.indexOf(deleteArray.find(child => child.id === Number(id)))
      deleteArray.splice(indexDelete, 1)
      dispatch(branchesAdd(deleteArray))
      setModal(false)
    })
  }

  //Function for open modal in table
  const openModal = event => {
    const id = event.target.dataset.value
    if (event.target.value === 'add') {
      reset()
      setCurrentForm('add')
      setModal(true)
    } else {
      setCurrentId(id)
      const promise = new Promise(resolve => {
        ajax.request({ id: id }, 'manager/branches/getOne', 'post', resolve)
      })
      promise.then(data => {
        setValue('editValues', { name: data.data.name, type: data.data.type }, { shouldValidate: true })
        setModal(true)
      })
    }
  }

  return (
    <section className='branches'>
      <div className='addNew'>
        <h2>Branches</h2>
        <button value='add' onClick={event => openModal(event)}>
          Add
        </button>
      </div>
      <div className='table' style={!allBranches ? { justifyContent: 'center', display: 'flex' } : null}>
        {branches}
      </div>
      <Modal active={modal} setActive={setModal}>
        <h2>Branches info</h2>
        <form onSubmit={handleSubmit(onSubmitSave)} autoComplete='off'>
          <label>
            <p>
              Name <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('editValues.name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('editValues') && errors.editValues.hasOwnProperty('name') ? errors.editValues.name.message : null}
          </span>
          <label>
            <p>Type</p>
            <select
              {...register('editValues.type', {
                required: 'This field is required',
              })}>
              <option value='2'>Woman</option>
              <option value='1' selected={getValues('editValues.type') === '1' ? 'selected' : null}>
                Man
              </option>
            </select>
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('editValues') && errors.editValues.hasOwnProperty('type') ? errors.editValues.type.message : null}
          </span>
          <div className='buttons'>
            <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
            <span className='sendButton' onClick={() => setModal(false)}>
              Cancel
            </span>
          </div>
        </form>
      </Modal>
      <Rooms modalRooms={modalRooms} setModalRooms={setModalRooms} idRooms={idRooms} titleBranch={titleBranch} setIdRooms={setIdRooms} />
    </section>
  )
}
