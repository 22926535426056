import { createSlice } from '@reduxjs/toolkit'

const students = createSlice({
  name: 'students',
  initialState: {
    students: false,
  },
  reducers: {
    studentsAdd(state, action) {
      state.students = action.payload
    },
  },
})

export const { studentsAdd } = students.actions
export default students.reducer
