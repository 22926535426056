import Modal from '../modal'
import Search from '../search'
import Loading from '../loading'
import Pagination from '../pagination'
import useAjax from '../../hooks/useAjax'
import { useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { courseAdd } from '../../store/slices/education'
import { curriculumAdd } from '../../store/slices/education'

import '../../styles/education.scss'

export default function Course() {
  //Initialization state manager
  const ajax = useAjax()
  const dispatch = useDispatch()
  const [contentPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false)
  const [planName, setPlanName] = useState(null)
  const [staffList, setStaffList] = useState('')
  const [currentId, setCurrentId] = useState('')
  const [pagination, setPagination] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [modalButton, setModalButton] = useState(null)
  const [currentRequest, setCurrentRequest] = useState('')
  const allCurriculum = useSelector(state => state.educationReducer.curriculum)
  const allCourses = useSelector(state => state.educationReducer.course)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      staffValues: {
        name: '',
        education_plan_id: '',
        general_education_time: '',
        max_practice_time: '',
        max_checks_time: '',
        max_budget: '',
      },
    },
    reValidateMode: 'onChange',
  })

  //Request for delete information from table
  const deleteStaff = useCallback(
    event => {
      const promise = new Promise(resolve => {
        ajax.request({ id: event.target.dataset.id }, 'manager/courses/delete', 'post', resolve)
      })
      promise.then(data => {
        const deleteArray = allCourses.slice()
        const indexDelete = deleteArray.indexOf(deleteArray.find(child => child.id === Number(event.target.dataset.id)))
        deleteArray.splice(indexDelete, 1)
        dispatch(courseAdd(deleteArray))
        setModal(false)
      })
    },
    [dispatch, allCourses]
  )

  //Function for open modal edit or add
  const openModal = useCallback(
    event => {
      if (event.target.value === 'addNew') {
        reset()
        setCurrentRequest('add')
        setModalButton(
          <span className='sendButton' onClick={() => setModal(false)}>
            Cancel
          </span>
        )
        setModal(true)
      } else {
        setCurrentRequest('edit')
        const id = event.target.closest('.item').dataset.value
        setModalButton(
          <span className='sendButton deleteButton' data-id={id} onClick={event => deleteStaff(event)}>
            Delete
          </span>
        )
        setCurrentId(id)
        const promise = new Promise(resolve => {
          ajax.request({ id }, 'manager/courses/getOne', 'post', resolve)
        })
        promise.then(data => {
          setValue('staffValues', data.data, { shouldValidate: true })
          setModal(true)
        })
      }
    },
    [reset, setValue, deleteStaff]
  )

  //Get all request
  useEffect(() => {
    if (allCourses === false) {
      const promise = new Promise(resolve => {
        ajax.request(null, 'manager/courses/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(courseAdd(newArr))
      })
    }
  }, [allCourses, dispatch])

  //Function for paste in table
  useEffect(() => {
    if (allCourses) {
      const lastPageIndex = currentPage * contentPerPage
      const firstPageIndex = lastPageIndex - contentPerPage
      const currentMyPage = allCourses
        .filter(
          element =>
            element.name.toLowerCase().includes(search.toLowerCase()) + element.education_plan_name.toLowerCase().includes(search.toLowerCase())
        )
        .slice(firstPageIndex, lastPageIndex)
      if (currentMyPage.length === 0) {
        setStaffList(<p className='nothingFound'>Nothing found</p>)
      } else {
        setStaffList(
          Object.values(currentMyPage).map(child => {
            return (
              <div className='item mainItem' key={child.id} data-value={child.id} onClick={event => openModal(event)}>
                <p title={child.name} data-value={child.id}>
                  {child.name}
                </p>
                <p title={child.education_plan_name} data-value={child.id}>
                  {child.education_plan_name}
                </p>
                <p title={child.general_education_time} data-value={child.id}>
                  {child.general_education_time}
                </p>
                <p title={child.max_practice_time} data-value={child.id}>
                  {child.max_practice_time}
                </p>
                <p title={child.max_checks_time} data-value={child.id}>
                  {child.max_checks_time}
                </p>
                <p title={child.max_budget} data-value={child.id}>
                  {child.max_budget}
                </p>
              </div>
            )
          })
        )
        setPagination(
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={search}
            allItems={allCourses}
            contentPerPage={contentPerPage}
            name={'name'}
          />
        )
      }
    } else {
      setStaffList(<Loading />)
      setPagination(null)
    }
  }, [allCourses, search, currentPage, contentPerPage, openModal])

  const onSubmit = data => {
    const currentPlanName = allCurriculum.indexOf(allCurriculum.find(child => child.id === Number(data.staffValues.education_plan_id)))
    if (currentRequest === 'add') {
      const promise = new Promise(resolve => {
        ajax.request(data.staffValues, 'manager/courses/add', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allCourses.slice()
        newArray.unshift({ ...data.data, education_plan_name: allCurriculum[currentPlanName].name })
        dispatch(courseAdd(newArray))
        reset()
        setModal(false)
      })
    } else {
      const body = { ...data.staffValues, id: Number(currentId) }
      const promise = new Promise(resolve => {
        ajax.request(body, 'manager/courses/update', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allCourses.slice()
        const currentEdit = newArray.indexOf(newArray.find(child => child.id === Number(currentId)))
        newArray[currentEdit] = { ...body, education_plan_name: allCurriculum[currentPlanName].name }
        dispatch(courseAdd(newArray))
        setModal(false)
        reset()
      })
    }
  }

  //Request for plan name
  useEffect(() => {
    if (allCurriculum) {
      setPlanName(
        allCurriculum.map(child => {
          return (
            <option value={child.id} key={child.id}>
              {child.name}
            </option>
          )
        })
      )
    } else {
      const promise = new Promise(resolve => {
        ajax.request(null, 'manager/educationPlans/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(curriculumAdd(newArr))
      })
    }
  }, [allCurriculum, dispatch])

  return (
    <section className='staff'>
      <div className='addNew'>
        <h2>Courses</h2>
        <Search search={search} setSearch={setSearch} setCurrentPage={setCurrentPage} />
        <button value='addNew' onClick={event => openModal(event)}>
          Add
        </button>
      </div>
      <div className='table'>
        <div className='item title'>
          <h2>Course name</h2>
          <h2>Plan name</h2>
          <h2>General education time</h2>
          <h2>Max practice time</h2>
          <h2>Max checks time</h2>
          <h2>Max budget</h2>
        </div>
        {staffList}
      </div>
      {pagination}
      <Modal active={modal} setActive={setModal}>
        <h2>Courses info</h2>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <label>
            <p>
              Plan name <span className='requiredInput'>*</span>
            </p>
            <select
              {...register('staffValues.education_plan_id', {
                required: 'This field is required',
              })}>
              {planName}
            </select>
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('education_plan_id')
              ? errors.staffValues.education_plan_id.message
              : null}
          </span>
          <label>
            <p>
              Course name <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('staffValues.name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('name') ? errors.staffValues.name.message : null}
          </span>

          <label>
            <p>
              General education time <span className='requiredInput'>*</span>
            </p>
            <input
              type='number'
              {...register('staffValues.general_education_time', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('general_education_time')
              ? errors.staffValues.general_education_time.message
              : null}
          </span>

          <label>
            <p>
              Max practice time <span className='requiredInput'>*</span>
            </p>
            <input
              type='number'
              {...register('staffValues.max_practice_time', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('max_practice_time')
              ? errors.staffValues.max_practice_time.message
              : null}
          </span>

          <label>
            <p>
              Max checks time <span className='requiredInput'>*</span>
            </p>
            <input
              type='number'
              {...register('staffValues.max_checks_time', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('max_checks_time')
              ? errors.staffValues.max_checks_time.message
              : null}
          </span>

          <label>
            <p>
              Max budget <span className='requiredInput'>*</span>
            </p>
            <input
              type='number'
              {...register('staffValues.max_budget', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('max_budget') ? errors.staffValues.max_budget.message : null}
          </span>

          <div className='buttons'>
            <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
            {modalButton}
          </div>
        </form>
      </Modal>
    </section>
  )
}
