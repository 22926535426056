import { useTranslation } from 'react-i18next'
import Cycle from '../components/education/cycle'
import React, { useEffect, useState } from 'react'
import Courses from '../components/education/course'
import { buttonsAdd } from '../store/slices/buttons'
import { useDispatch, useSelector } from 'react-redux'
import Curriculum from '../components/education/curriculum'

import '../styles/staff.scss'
import '../styles/education.scss'

import adminIcon from '../images/icons/task.png'
import workerIcon from '../images/icons/education.png'
import teacherIcon from '../images/menu/courses.svg'

export default function Education() {
  //Translate initialization
  const { t } = useTranslation()
  const dispatch = useDispatch()
  document.title = 'Education | ' + t('name')

  //Initialization state manager
  const [table, setTable] = useState(<Curriculum />)
  const allButtons = useSelector(state => state.buttonsReducer.buttons)

  useEffect(() => {
    dispatch(buttonsAdd([adminIcon, workerIcon, teacherIcon]))
    return () => dispatch(buttonsAdd(null))
  }, [dispatch])

  useEffect(() => {
    if (allButtons) {
      const tableButtons = document.querySelector('.tableButtons').querySelectorAll('button')
      tableButtons[0].onclick = () => setTable(<Curriculum />)
      tableButtons[1].onclick = () => setTable(<Cycle />)
      tableButtons[2].onclick = () => setTable(<Courses />)
    }
  }, [allButtons])

  return table
}
