import Modal from '../modal'
import Search from '../search'
import Loading from '../loading'
import Pagination from '../pagination'
import useAjax from '../../hooks/useAjax'
import { useForm } from 'react-hook-form'
import { workers } from '../../store/slices/staff'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { requestGetAll } from '../../store/slices/additional/positions'

export default function Workers() {
  //Initialization state manager
  const ajax = useAjax().request
  const dispatch = useDispatch()
  const [contentPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false)
  const [staffList, setStaffList] = useState('')
  const [currentId, setCurrentId] = useState('')
  const [pagination, setPagination] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [modalButton, setModalButton] = useState(null)
  const [currentRequest, setCurrentRequest] = useState('')
  const [positionsRequest, setPositionsRequest] = useState(null)
  const allWorkers = useSelector(state => state.staffReducer.workers)
  const allPositions = useSelector(state => state.positionsReducer.positions)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      staffValues: {
        id_number: '',
        name: '',
        last_name: '',
        phone: '',
        email: '',
        position_id: '',
        education: '',
      },
    },
    reValidateMode: 'onChange',
  })

  //Request for delete information from table
  const deleteStaff = useCallback(
    event => {
      const promise = new Promise(resolve => {
        ajax({ id: event.target.dataset.id }, 'manager/workers/delete', 'post', resolve)
      })
      promise.then(data => {
        const deleteArray = allWorkers.slice()
        const indexDelete = deleteArray.indexOf(deleteArray.find(child => child.id === Number(event.target.dataset.id)))
        deleteArray.splice(indexDelete, 1)
        dispatch(workers(deleteArray))
        setModal(false)
      })
    },
    [dispatch, allWorkers, ajax]
  )

  //Function for open modal edit or add
  const openModal = useCallback(
    event => {
      if (event.target.value === 'addNew') {
        reset()
        setCurrentRequest('add')
        setModalButton(
          <span className='sendButton' onClick={() => setModal(false)}>
            Cancel
          </span>
        )
        setModal(true)
      } else {
        setCurrentRequest('edit')
        const id = event.target.closest('.item').dataset.value
        setModalButton(
          <span className='sendButton deleteButton' data-id={id} onClick={event => deleteStaff(event)}>
            Delete
          </span>
        )
        setCurrentId(id)
        const promise = new Promise(resolve => {
          ajax({ id }, 'manager/workers/getOne', 'post', resolve)
        })
        promise.then(data => {
          setValue('staffValues', data.data, { shouldValidate: true })
          setModal(true)
        })
      }
    },
    [reset, setValue, deleteStaff, ajax]
  )

  //Get all request
  useEffect(() => {
    if (allWorkers === null) {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/workers/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(workers(newArr))
      })
    }
  }, [allWorkers, dispatch, ajax])

  //Function for paste in table
  useEffect(() => {
    if (allWorkers) {
      const lastPageIndex = currentPage * contentPerPage
      const firstPageIndex = lastPageIndex - contentPerPage
      const currentMyPage = allWorkers
        .filter(
          element =>
            element.name.toLowerCase().includes(search.toLowerCase()) +
            element.last_name.toLowerCase().includes(search.toLowerCase()) +
            element.phone.toLowerCase().includes(search.toLowerCase()) +
            element.email.toLowerCase().includes(search.toLowerCase()) +
            element.id_number.toLowerCase().includes(search.toLowerCase())
        )
        .slice(firstPageIndex, lastPageIndex)
      if (currentMyPage.length === 0) {
        setStaffList(<p className='nothingFound'>Nothing found</p>)
      } else {
        setStaffList(
          Object.values(currentMyPage).map(child => {
            return (
              <div className='item mainItem' key={child.id} data-value={child.id} onClick={event => openModal(event)}>
                <p title={child.name} data-value={child.id}>
                  {child.name}
                </p>
                <p title={child.last_name} data-value={child.id}>
                  {child.last_name}
                </p>
                <p title={child.phone} data-value={child.id}>
                  {child.phone}
                </p>
                <p title={child.email} data-value={child.id}>
                  {child.email}
                </p>
                <p title={child.id_number} data-value={child.id}>
                  {child.id_number}
                </p>
                <p title={child.position_name} data-value={child.id}>
                  {child.position_name}
                </p>
                <p title={child.education} data-value={child.id}>
                  {child.education}
                </p>
              </div>
            )
          })
        )
        setPagination(
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={search}
            allItems={allWorkers}
            name={'name'}
            contentPerPage={contentPerPage}
          />
        )
      }
    } else {
      setStaffList(<Loading />)
      setPagination(null)
    }
  }, [allWorkers, search, currentPage, contentPerPage, openModal])

  const onSubmit = data => {
    if (currentRequest === 'add') {
      const body = { ...data.staffValues, password: '123456', password_confirmation: '123456' }
      const promise = new Promise(resolve => {
        ajax(body, 'manager/workers/add', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allWorkers.slice()
        newArray.unshift({ ...data.data, position_name: allPositions[Number(data.data.position_id)].name })
        dispatch(workers(newArray))
        reset()
        setModal(false)
      })
    } else {
      const body = { ...data.staffValues, id: Number(currentId), password: '123456', password_confirmation: '123456' }
      const promise = new Promise(resolve => {
        ajax(body, 'manager/workers/update', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allWorkers.slice()
        const currentEdit = newArray.indexOf(newArray.find(child => child.id === Number(currentId)))
        const positionName = allPositions.indexOf(allPositions.find(child => child.id === Number(body.position_id)))
        newArray[currentEdit] = { ...body, position_name: allPositions[positionName].name, position_id: Number(body.position_id) }
        dispatch(workers(newArray))
        setModal(false)
        reset()
      })
    }
  }

  //Request for all positions
  useEffect(() => {
    if (!allPositions) {
      dispatch(requestGetAll())
    }
  }, [allPositions, dispatch])

  useEffect(() => {
    if (allPositions) {
      setPositionsRequest(
        Object.values(allPositions).map(child => {
          return (
            <option value={child.id} key={child.id}>
              {child.name}
            </option>
          )
        })
      )
    }
  }, [allPositions])

  return (
    <section className='staff'>
      <div className='addNew'>
        <h2>Staff</h2>
        <Search search={search} setSearch={setSearch} setCurrentPage={setCurrentPage} />
        <button value='addNew' onClick={event => openModal(event)}>
          Add
        </button>
      </div>
      <div className='table'>
        <div className='item title'>
          <h2>Name</h2>
          <h2>Surname</h2>
          <h2>Phone number</h2>
          <h2>E-mail</h2>
          <h2>ID</h2>
          <h2>Position name</h2>
          <h2>Education</h2>
        </div>
        {staffList}
      </div>
      {pagination}
      <Modal active={modal} setActive={setModal}>
        <h2>Staff info</h2>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <div className='labelDiv'>
            <div>
              <label>
                <p>
                  Name <span className='requiredInput'>*</span>
                </p>
                <input
                  {...register('staffValues.name', {
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'Min length 3',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Max length 50',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('name') ? errors.staffValues.name.message : null}
              </span>
            </div>
            <div>
              <label>
                <p>
                  Surname <span className='requiredInput'>*</span>
                </p>
                <input
                  {...register('staffValues.last_name', {
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'Min length 6',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Max length 100',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('last_name') ? errors.staffValues.last_name.message : null}
              </span>
            </div>
          </div>
          <div className='labelDiv'>
            <div>
              <label>
                <p>
                  Phone number <span className='requiredInput'>*</span>
                </p>
                <input
                  type='number'
                  {...register('staffValues.phone', {
                    required: 'This field is required',
                    minLength: {
                      value: 6,
                      message: 'Min length 6',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Max length 100',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('phone') ? errors.staffValues.phone.message : null}
              </span>
            </div>
            <div>
              <label>
                <p>
                  Email <span className='requiredInput'>*</span>
                </p>
                <input
                  {...register('staffValues.email', {
                    required: 'This field is required',
                    minLength: {
                      value: 6,
                      message: 'Min length 6',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Max length 100',
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('email') ? errors.staffValues.email.message : null}
              </span>
            </div>
          </div>
          <label>
            <p>ID</p>
            <input
              {...register('staffValues.id_number', {
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('id_number') ? errors.staffValues.id_number.message : null}
          </span>
          <label>
            Position name
            <select
              {...register('staffValues.position_id', {
                required: 'This field is required',
              })}>
              {positionsRequest}
            </select>
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('position_id') ? errors.staffValues.position_id.message : null}
          </span>
          <label>
            Education
            <input
              {...register('staffValues.education', {
                maxLength: {
                  value: 100,
                  message: 'Max length 100',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('education') ? errors.staffValues.education.message : null}
          </span>
          <div className='buttons'>
            <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
            {modalButton}
          </div>
        </form>
      </Modal>
    </section>
  )
}
