import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import RightButtons from './rightButtons'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { userLogin } from '../store/slices/login'
import { useDispatch, useSelector } from 'react-redux'

import Login from '../pages/login'
import Header from './header'
import Footer from './footer'
import Aside from '../components/aside'

export default function Router() {
  const dispatch = useDispatch()
  const { language } = useParams()
  const { i18n } = useTranslation()
  const login = useSelector(state => state.loginReducer.userLogin)

  useEffect(() => {
    if (localStorage.userLogin || localStorage.userLogin === 'false') {
      dispatch(userLogin(true))
    } else {
      dispatch(userLogin(false)) //Edit to false for activate login page
    }
  }, [login, dispatch])

  useEffect(() => {
    const changeLanguage = language => {
      i18n.changeLanguage(language)
    }
    if (language === 'ru') {
      changeLanguage('ru')
    }
    if (language === 'en') {
      changeLanguage('en')
    }
  }, [language, i18n])

  const verification = login ? (
    <>
      <Header />
      <main className='mainHome'>
        <Aside />
        <section className='mainSection'>
          <div className='border'>
            <Outlet />
          </div>
        </section>
        <RightButtons />
      </main>
      <Footer />
    </>
  ) : (
    <Login />
  )
  return verification
}
