import { useTranslation } from 'react-i18next'
import Workers from '../components/staff/workers'
import React, { useEffect, useState } from 'react'
import Teachers from '../components/staff/teachers'
import { buttonsAdd } from '../store/slices/buttons'
import { useDispatch, useSelector } from 'react-redux'
import Administration from '../components/staff/administration'

import '../styles/staff.scss'

import adminIcon from '../images/icons/admin.png'
import workerIcon from '../images/icons/worker.png'
import teacherIcon from '../images/icons/teacher.png'

export default function Staff() {
  //Translate initialization
  const { t } = useTranslation()
  const dispatch = useDispatch()
  document.title = 'Staff | ' + t('name')

  //Initialization state manager
  const [table, setTable] = useState(<Administration />)
  const allButtons = useSelector(state => state.buttonsReducer.buttons)

  useEffect(() => {
    dispatch(buttonsAdd([adminIcon, workerIcon, teacherIcon]))
    return () => dispatch(buttonsAdd(null))
  }, [dispatch])

  useEffect(() => {
    if (allButtons) {
      const tableButtons = document.querySelector('.tableButtons').querySelectorAll('button')
      tableButtons[0].onclick = () => setTable(<Administration />)
      tableButtons[1].onclick = () => setTable(<Workers />)
      tableButtons[2].onclick = () => setTable(<Teachers />)
    }
  }, [allButtons])

  return table
}
