import React from 'react'

import '../styles/search.scss'

import searchIcon from '../images/search.png'

export default function Search({ search, setSearch, setCurrentPage }) {
  return (
    <div className='search'>
      <input
        type='text'
        placeholder='Search...'
        value={search}
        onChange={function (e) {
          setSearch(e.target.value)
          setCurrentPage(1)
        }}
      />
      <button>
        <img src={searchIcon} alt='search icon' />
      </button>
    </div>
  )
}
