import React from 'react'
import { useTranslation } from 'react-i18next'
import Exemptions from '../components/additional/exemptions'
import ExemptionsStatus from '../components/additional/exemptionsStatus'
import Exercise from '../components/additional/exercise'
import Objects from '../components/additional/objects'
import Positions from '../components/additional/positions'
import EmployerStatus from '../components/additional/employerStatus'
import Statuses from '../components/additional/statuses'
import StatusPlacement from '../components/additional/statusPlacement'

import '../styles/additional.scss'

export default function Additional() {
  //Translate initialization
  const { t } = useTranslation()
  document.title = 'Additional Tables | ' + t('name')

  return (
    <section className='additional'>
      <h1>Additional Tables</h1>
      <div className='mainTable'>
        <h2>Students</h2>
        <Exemptions />
        <ExemptionsStatus />
        <Statuses />
        <StatusPlacement />
      </div>
      <div className='mainTable'>
        <h2>Workers</h2>
        <Positions />
        <EmployerStatus />
      </div>
      <div className='mainTable'>
        <h2>Payments</h2>
        <Objects />
      </div>
      <div className='mainTable'>
        <h2>Courses</h2>
        <Exercise />
      </div>
    </section>
  )
}
