import { createSlice } from '@reduxjs/toolkit'

const buttons = createSlice({
  name: 'buttons',
  initialState: {
    buttons: null,
  },
  reducers: {
    buttonsAdd(state, action) {
      state.buttons = action.payload
    },
  },
})

export const { buttonsAdd } = buttons.actions
export default buttons.reducer
