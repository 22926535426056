import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/footer.scss'

export default function Footer() {
  return (
    <footer>
      <Link to='/'>Warranties</Link>
      <Link to='/'>Agreements</Link>
      <Link to='/'>Terms & Conditions</Link>
      <Link to='/'>SLA</Link>
      <p>© 2008 SafeBit Business Computing. All rights reserved</p>
    </footer>
  )
}
