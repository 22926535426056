import React from 'react'
import { useSelector } from 'react-redux'

export default function RightButtons() {
  const allButtons = useSelector(state => state.buttonsReducer.buttons)
  const currentButton = allButtons
    ? allButtons.map(child => {
        return (
          <button key={child}>
            <img src={child} alt='icon' />
          </button>
        )
      })
    : null

  const insert = currentButton ? <div className='tableButtons'>{currentButton}</div> : null

  return insert
}
