import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Meeting from '../components/courses/meeting'
import { buttonsAdd } from '../store/slices/buttons'
import { useDispatch, useSelector } from 'react-redux'
import Exercises from '../components/courses/exercises'
import Enrollment from '../components/courses/enrollment'
import CourseOpening from '../components/courses/courseOpening'

import courseIcon from '../images/icons/admin.png'
import meetingIcon from '../images/icons/worker.png'
import exercisesIcon from '../images/icons/email.svg'
import enrollmentIcon from '../images/icons/teacher.png'

import '../styles/staff.scss'
import '../styles/courses.scss'
import '../styles/additional.scss'

export default function Courses() {
  //Translate initialization
  const { t } = useTranslation()
  const dispatch = useDispatch()
  document.title = 'Courses | ' + t('name')

  //Initialization state manager
  const [table, setTable] = useState(<CourseOpening />)
  const allButtons = useSelector(state => state.buttonsReducer.buttons)

  useEffect(() => {
    dispatch(buttonsAdd([courseIcon, enrollmentIcon, meetingIcon, exercisesIcon]))
    return () => dispatch(buttonsAdd(null))
  }, [dispatch])

  useEffect(() => {
    if (allButtons && allButtons.length === 4) {
      const tableButtons = document.querySelector('.tableButtons').querySelectorAll('button')
      tableButtons[0].onclick = () => setTable(<CourseOpening />)
      tableButtons[1].onclick = () => setTable(<Enrollment />)
      tableButtons[2].onclick = () => setTable(<Meeting />)
      tableButtons[3].onclick = () => setTable(<Exercises />)
    }
  }, [allButtons])

  return table
}
