import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import '../styles/aside.scss'

import homeIcon from '../images/menu/dashboard.svg'
import branchesIcon from '../images/menu/branches.svg'
import exemptionsIcon from '../images/menu/database.png'
import staffIcon from '../images/menu/staff.png'
import studentsIcon from '../images/menu/student.png'
import workIcon from '../images/menu/briefcase.png'
import educationIcon from '../images/menu/blueprint.png'
import coursesIcon from '../images/menu/online-course.png'
import financeIcon from '../images/menu/tax.png'
import paymentsIcon from '../images/menu/credit-card.png'
import reviewsIcon from '../images/menu/review.png'

export default function Aside() {
  const { i18n } = useTranslation()

  return (
    <aside>
      <nav>
        <NavLink to='/'>
          <p>
            <img src={homeIcon} alt='homeIcon' />
            Dashboard
          </p>
        </NavLink>

        <NavLink to={i18n.language + '/branches'}>
          <p>
            <img src={branchesIcon} alt='employersIcon' /> Branches
          </p>
        </NavLink>
        <NavLink to={i18n.language + '/students'}>
          <p>
            <img src={studentsIcon} alt='studentsIcon' /> Students
          </p>
        </NavLink>
        <NavLink to={i18n.language + '/staff'}>
          <p>
            <img src={staffIcon} alt='staffIcon' /> Staff
          </p>
        </NavLink>

        <NavLink to={i18n.language + '/education'}>
          <p>
            <img src={educationIcon} alt='educationIcon' /> Education <br /> plan
          </p>
        </NavLink>
        <NavLink to={i18n.language + '/courses'}>
          <p>
            <img src={coursesIcon} alt='coursesIcon' /> Courses
          </p>
        </NavLink>

        <NavLink to={i18n.language + '/additional'}>
          <p>
            <img src={exemptionsIcon} alt='exemptionsIcon' /> Additional <br /> tables
          </p>
        </NavLink>

        <NavLink to={i18n.language + '/work'}>
          <p>
            <img src={workIcon} alt='workIcon' /> Work
          </p>
        </NavLink>

        <NavLink to={i18n.language + '/finance'}>
          <p>
            <img src={financeIcon} alt='financeIcon' /> Finance
          </p>
        </NavLink>
        <NavLink to={i18n.language + '/payments'}>
          <p>
            <img src={paymentsIcon} alt='paymentsIcon' /> Payments
          </p>
        </NavLink>
        <NavLink to={i18n.language + '/reviews'}>
          <p>
            <img src={reviewsIcon} alt='reviewsIcon' /> Reviews
          </p>
        </NavLink>
      </nav>
    </aside>
  )
}
