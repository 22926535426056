import Modal from '../modal'
import Search from '../search'
import Loading from '../loading'
import Pagination from '../pagination'
import useAjax from '../../hooks/useAjax'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { branchesAdd } from '../../store/slices/branches'
import React, { useCallback, useEffect, useState } from 'react'
import { cycleAdd, curriculumAdd } from '../../store/slices/education'

import '../../styles/education.scss'

export default function Cycle() {
  //Initialization state manager
  const ajax = useAjax().request
  const dispatch = useDispatch()
  const [contentPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false)
  const [staffList, setStaffList] = useState('')
  const [currentId, setCurrentId] = useState('')
  const [planName, setPlanName] = useState(null)
  const [pagination, setPagination] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [enrollment, setEnrollment] = useState(true)
  const [modalButton, setModalButton] = useState(null)
  const [studentsList, setStudentsList] = useState(null)
  const [currentRequest, setCurrentRequest] = useState('')
  const [studentsSearch, setStudentsSearch] = useState('')
  const [branchesSelect, setBranchesSelect] = useState(null)
  const [branchStudents, setBranchStudents] = useState(null)
  const [checkedStudents, setCheckedStudents] = useState([])
  const allCycle = useSelector(state => state.educationReducer.cycle)
  const allBranches = useSelector(state => state.branchesReducer.branches)
  const allCurriculum = useSelector(state => state.educationReducer.curriculum)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      staffValues: {
        branch_id: '',
        education_plan_id: '',
        opening_date: '',
        education_start: '',
        education_end: '',
        course_of_study: '',
      },
    },
    reValidateMode: 'onChange',
  })

  //Request for delete information from table
  const deleteStaff = useCallback(
    event => {
      const promise = new Promise(resolve => {
        ajax({ id: event.target.dataset.id }, 'manager/educationCicles/delete', 'post', resolve)
      })
      promise.then(data => {
        const deleteArray = allCycle.slice()
        const indexDelete = deleteArray.indexOf(deleteArray.find(child => child.id === Number(event.target.dataset.id)))
        deleteArray.splice(indexDelete, 1)
        dispatch(cycleAdd(deleteArray))
        setModal(false)
      })
    },
    [dispatch, allCycle, ajax]
  )

  //Function for open modal edit or add
  const openModal = useCallback(
    event => {
      if (event.target.value === 'addNew') {
        reset()
        setCurrentRequest('add')
        setModalButton(
          <span className='sendButton' onClick={() => setModal(false)}>
            Cancel
          </span>
        )
        setModal(true)
      } else {
        setCurrentRequest('edit')
        const id = event.target.closest('.item').dataset.value
        setModalButton(
          <span className='sendButton deleteButton' data-id={id} onClick={event => deleteStaff(event)}>
            Delete
          </span>
        )
        setCurrentId(id)
        const promise = new Promise(resolve => {
          ajax({ id }, 'manager/educationCicles/getOne', 'post', resolve)
        })
        promise.then(data => {
          setValue('staffValues', data.data.data, { shouldValidate: true })
          setStudentsList(data.data.students)
          if (data.data.students) {
            data.data.students.forEach(child => {
              setCheckedStudents(c => [...checkedStudents, String(child.id)])
            })
          }
          setModal(true)
        })
      }
    },
    [reset, setValue, deleteStaff, ajax, checkedStudents]
  )

  const renderStudents = studentsList
    ? studentsList.map(child => {
        return (
          <div className='item' key={child.id_number}>
            <p title={child.name} data-value={child.id}>
              {child.name}
            </p>
            <p title={child.last_name} data-value={child.id}>
              {child.last_name}
            </p>
            <p title={child.phone} data-value={child.id}>
              {child.phone}
            </p>
            <p title={child.id_number} data-value={child.id}>
              {child.id_number}
            </p>
          </div>
        )
      })
    : null

  //Get all request
  useEffect(() => {
    if (allCycle === false) {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/educationCicles/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(cycleAdd(newArr))
      })
    }
  }, [allCycle, dispatch, ajax])

  //Get all students
  const branch = getValues().staffValues.branch_id
  useEffect(() => {
    if (branch) {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/students/getAll', 'post', resolve)
      })
      promise.then(data => {
        setBranchStudents(data.data)
      })
    }
  }, [branch, ajax])

  const addNewStudent = event => {
    if (event.target.checked) {
      setCheckedStudents([...checkedStudents, event.target.dataset.value])
    } else {
      const newArray = checkedStudents
      newArray.splice(checkedStudents.indexOf(event.target.dataset.value), 1)
      setCheckedStudents(newArray)
    }
  }

  const enrollmentStudents = branchStudents
    ? branchStudents
        .filter(child => child.branch_id === branch)
        .map(child => {
          return (
            <div className='item' key={child.id_number}>
              <input
                type='checkbox'
                data-value={child.id}
                defaultChecked={studentsList.find(element => element.id === child.id) ? 'checked' : null}
                onChange={event => addNewStudent(event)}
              />
              <p title={child.name} data-value={child.id}>
                {child.name}
              </p>
              <p title={child.last_name} data-value={child.id}>
                {child.last_name}
              </p>
              <p title={child.phone} data-value={child.id}>
                {child.phone}
              </p>
              <p title={child.id_number} data-value={child.id}>
                {child.id_number}
              </p>
            </div>
          )
        })
    : null

  //Function for paste in table
  useEffect(() => {
    if (allCycle) {
      const lastPageIndex = currentPage * contentPerPage
      const firstPageIndex = lastPageIndex - contentPerPage
      const currentMyPage = allCycle
        .filter(
          element =>
            element.branch_name.toLowerCase().includes(search.toLowerCase()) +
            element.education_plan_name.toLowerCase().includes(search.toLowerCase())
        )
        .slice(firstPageIndex, lastPageIndex)
      if (currentMyPage.length === 0) {
        setStaffList(<p className='nothingFound'>Nothing found</p>)
      } else {
        setStaffList(
          Object.values(currentMyPage).map(child => {
            return (
              <div className='item mainItem' key={child.id} data-value={child.id} onClick={event => openModal(event)}>
                <p title={child.education_plan_name} data-value={child.id}>
                  {child.education_plan_name}
                </p>
                <p title={child.branch_name} data-value={child.id}>
                  {child.branch_name}
                </p>
                <p title={child.opening_date} data-value={child.id}>
                  {child.opening_date}
                </p>
                <p title={child.education_start} data-value={child.id}>
                  {child.education_start}
                </p>
                <p title={child.education_end} data-value={child.id}>
                  {child.education_end}
                </p>
                <p title={child.course_of_study} data-value={child.id}>
                  {child.course_of_study}
                </p>
              </div>
            )
          })
        )
        setPagination(
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={search}
            allItems={allCycle}
            contentPerPage={contentPerPage}
            name={'education_plan_name'}
          />
        )
      }
    } else {
      setStaffList(<Loading />)
      setPagination(null)
    }
  }, [allCycle, search, currentPage, contentPerPage, openModal])

  const onSubmit = data => {
    const currentBranchName = allBranches.indexOf(allBranches.find(child => child.id === Number(data.staffValues.branch_id)))
    const currentPlanName = allCurriculum.indexOf(allCurriculum.find(child => child.id === Number(data.staffValues.education_plan_id)))
    if (currentRequest === 'add') {
      const promise = new Promise(resolve => {
        ajax(data.staffValues, 'manager/educationCicles/add', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allCycle.slice()
        newArray.unshift({ ...data.data, branch_name: allBranches[currentBranchName].name, education_plan_name: allCurriculum[currentPlanName].name })
        dispatch(cycleAdd(newArray))
        reset()
        setModal(false)
      })
    } else {
      const myStudents = []
      checkedStudents.forEach(child => {
        myStudents.push({ id: child })
      })
      const body = {
        ...data.staffValues,
        id: Number(currentId),
        students: myStudents,
      }
      const promise = new Promise(resolve => {
        ajax(body, 'manager/educationCicles/update', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allCycle.slice()
        const currentEdit = newArray.indexOf(newArray.find(child => child.id === Number(currentId)))
        newArray[currentEdit] = {
          ...body,
          branch_name: allBranches[currentBranchName].name,
          education_plan_name: allCurriculum[currentPlanName].name,
        }
        dispatch(cycleAdd(newArray))
        setModal(false)
        reset()
      })
    }
  }

  //Request for plan name
  useEffect(() => {
    if (allCurriculum) {
      setPlanName(
        allCurriculum.map(child => {
          return (
            <option value={child.id} key={child.id}>
              {child.name}
            </option>
          )
        })
      )
    } else {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/educationPlans/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(curriculumAdd(newArr))
      })
    }
  }, [allCurriculum, dispatch, ajax])

  //Request for all branches
  useEffect(() => {
    if (allBranches) {
      setBranchesSelect(
        allBranches.map(child => {
          return (
            <option value={child.id} key={child.id}>
              {child.name}
            </option>
          )
        })
      )
    } else {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/branches/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(branchesAdd(newArr))
      })
    }
  }, [allBranches, dispatch, ajax])

  return (
    <section className='staff cycle'>
      <div className='addNew'>
        <h2>Cycle</h2>
        <Search search={search} setSearch={setSearch} setCurrentPage={setCurrentPage} />
        <button value='addNew' onClick={event => openModal(event)}>
          Add
        </button>
      </div>
      <div className='table'>
        <div className='item title'>
          <h2>Plan name</h2>
          <h2>Branch name</h2>
          <h2>Opening date</h2>
          <h2>Education start</h2>
          <h2>Education end</h2>
          <h2>Course of study</h2>
        </div>
        {staffList}
      </div>
      {pagination}
      <Modal active={modal} setActive={setModal}>
        <div className='mainCycle'>
          <div className='cycleModal'>
            <h2>Cycle info</h2>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <label>
                <p>
                  Plan name <span className='requiredInput'>*</span>
                </p>
                <select
                  {...register('staffValues.education_plan_id', {
                    required: 'This field is required',
                  })}>
                  {planName}
                </select>
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('education_plan_id')
                  ? errors.staffValues.education_plan_id.message
                  : null}
              </span>
              <label>
                <p>
                  Branch name <span className='requiredInput'>*</span>
                </p>
                <select
                  {...register('staffValues.branch_id', {
                    required: 'This field is required',
                  })}>
                  {branchesSelect}
                </select>
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('branch_id') ? errors.staffValues.branch_id.message : null}
              </span>
              <label>
                <p>
                  Opening date <span className='requiredInput'>*</span>
                </p>
                <input
                  type='number'
                  {...register('staffValues.opening_date', {
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'Min length 3',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Max length 50',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('opening_date')
                  ? errors.staffValues.opening_date.message
                  : null}
              </span>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>
                      Education start <span className='requiredInput'>*</span>
                    </p>
                    <input
                      type='number'
                      {...register('staffValues.education_start', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Min length 3',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('education_start')
                      ? errors.staffValues.education_start.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>
                      Education end <span className='requiredInput'>*</span>
                    </p>
                    <input
                      type='number'
                      {...register('staffValues.education_end', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Min length 3',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('education_end')
                      ? errors.staffValues.education_end.message
                      : null}
                  </span>
                </div>
              </div>
              <label>
                <p>
                  Course of study <span className='requiredInput'>*</span>
                </p>
                <input
                  type='number'
                  {...register('staffValues.course_of_study', {
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'Min length 3',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Max length 50',
                    },
                  })}
                />
              </label>

              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('course_of_study')
                  ? errors.staffValues.course_of_study.message
                  : null}
              </span>
              <div className='buttons'>
                <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
              </div>
            </form>
          </div>
          <div className='cycleStudents'>
            <div className='searchMain'>
              <Search search={studentsSearch} setSearch={setStudentsSearch} setCurrentPage={setCurrentPage} />
              <button className='enrollment' onClick={() => setEnrollment(!enrollment)}>
                Enrollment
              </button>
            </div>
            <div className='table'>
              <div className='item title'>
                <h2>Name</h2>
                <h2>Last name</h2>
                <h2>Phone</h2>
                <h2>ID</h2>
              </div>
              {enrollment ? renderStudents : enrollmentStudents}
            </div>
          </div>
        </div>
        <div className='buttons'>{modalButton}</div>
      </Modal>
    </section>
  )
}
