import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Staff from './pages/staff'
import Error from './pages/error'
import Courses from './pages/courses'
import Branches from './pages/branches'
import Students from './pages/students'
import Router from './components/router'
import Dashboard from './pages/dashboard'
import Education from './pages/education'
import Additional from './pages/additional'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Router />}>
          <Route index element={<Dashboard />} />
          <Route path='/:language' element={<Dashboard />} />
          <Route path='/:language/branches' element={<Branches />} />
          <Route path='/:language/additional' element={<Additional />} />
          <Route path='/:language/staff' element={<Staff />} />
          <Route path='/:language/students' element={<Students />} />
          <Route path='/:language/education' element={<Education />} />
          <Route path='/:language/courses' element={<Courses />} />
          <Route path='*' element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
