import { createSlice } from '@reduxjs/toolkit'

const courses = createSlice({
  name: 'courses',
  initialState: {
    courses: null,
  },
  reducers: {
    coursesAdd(state, action) {
      state.courses = action.payload
    },
  },
})

export const { coursesAdd } = courses.actions
export default courses.reducer
