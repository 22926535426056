import { createSlice } from '@reduxjs/toolkit'

const branches = createSlice({
  name: 'branches',
  initialState: {
    branches: false,
    rooms: false,
  },
  reducers: {
    branchesAdd(state, action) {
      state.branches = action.payload
    },
  },
})

export const { branchesAdd } = branches.actions
export default branches.reducer
