import staff from './slices/staff'
import login from './slices/login'
import buttons from './slices/buttons'
import courses from './slices/courses'
import branches from './slices/branches'
import students from './slices/students'
import education from './slices/education'
import { configureStore } from '@reduxjs/toolkit'
import objects from './slices/additional/objects'
import statuses from './slices/additional/statuses'
import exercise from './slices/additional/exercise'
import positions from './slices/additional/positions'
import exemptions from './slices/additional/exemptions'
import employerStatus from './slices/additional/employerStatus'
import statusPlacement from './slices/additional/statusPlacement'
import exemptionsStatus from './slices/additional/exemptionsStatus'

const store = configureStore({
  reducer: {
    staffReducer: staff,
    loginReducer: login,
    buttonsReducer: buttons,
    coursesReducer: courses,
    objectsReducer: objects,
    studentsReducer: students,
    branchesReducer: branches,
    exerciseReducer: exercise,
    statusesReducer: statuses,
    educationReducer: education,
    positionsReducer: positions,
    exemptionsReducer: exemptions,
    employerStatusReducer: employerStatus,
    statusPlacementReducer: statusPlacement,
    exemptionsStatusReducer: exemptionsStatus,
  },
})

export default store
