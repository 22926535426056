import { createSlice } from '@reduxjs/toolkit'

const login = createSlice({
  name: 'login',
  initialState: {
    userLogin: false,
    currentUser: {},
  },
  reducers: {
    userAdd(state, action) {
      state.currentUser = action.payload
      localStorage.setItem('userLogin', true)
      localStorage.setItem('userToken', action.payload.token)
    },
    userLogin(state, action) {
      state.userLogin = action.payload
    },
  },
})

export const { userAdd, userLogin } = login.actions
export default login.reducer
