import Modal from './modal'
import useAjax from '../hooks/useAjax'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'

import '../styles/rooms.scss'

import editIcon from '../images/edit.png'
import deleteIcon from '../images/delete.png'

export default function Rooms({ idRooms, setIdRooms, titleBranch, modalRooms, setModalRooms }) {
  //Initialization state manager
  const ajax = useAjax()
  const dispatch = useDispatch()
  const [roomsList, setRoomsList] = useState(null)
  const [currentRoomsId, setCurrentRoomsId] = useState(null)

  //Form for rooms
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      roomsValues: {
        name: '',
        address: '',
        seats: '',
        tables: '',
        chairs: '',
        projector: '0',
        description: '',
      },
    },
    reValidateMode: 'onChange',
  })

  //Request for get all rooms
  useEffect(() => {
    if (idRooms && !roomsList) {
      const promise = new Promise(resolve => {
        ajax.request({ branch_id: idRooms }, 'manager/rooms/getAll', 'post', resolve)
      })
      promise.then(data => {
        setRoomsList(data.data)
        setModalRooms(true)
      })
    }
  }, [idRooms, ajax, modalRooms, setModalRooms, dispatch, roomsList])

  //Reset values when close modal rooms
  useEffect(() => {
    if (!modalRooms) {
      setRoomsList(null)
      setIdRooms(null)
    }
  }, [modalRooms, setIdRooms])

  const roomsMain = roomsList
    ? Object.values(roomsList).map(child => {
        return (
          <div className='item' key={child.id} data-branch={child.branch_id}>
            <p title={child.name}>{child.name}</p>
            <p title={child.address}>{child.address}</p>
            <p title={child.seats_nr}>{child.seats_nr}</p>
            <p title={child.tables_nr}>{child.tables_nr}</p>
            <p title={child.chairs_nr}>{child.chairs_nr}</p>
            <p>{child.projector === '1' ? 'Yes' : 'No'}</p>
            <p title={child.description}>{child.description}</p>
            <div className='itemButtons'>
              <button data-value={child.id} onClick={event => oneRooms(event)}>
                <img src={editIcon} alt='edit icon' data-value={child.id} />
              </button>
              <button data-value={child.id} onClick={event => deleteEmployer(event)}>
                <img src={deleteIcon} alt='delete icon' data-value={child.id} />
              </button>
            </div>
          </div>
        )
      })
    : null

  //Request for edit Rooms
  const oneRooms = useCallback(
    event => {
      document.querySelector('.sendButtonRooms').setAttribute('data-rooms', 'true')
      const body = {
        id: event.target.dataset.value,
        branch_id: idRooms,
      }
      setCurrentRoomsId(event.target.dataset.value)

      const promise = new Promise(resolve => {
        ajax.request(body, 'manager/rooms/getOne', 'post', resolve)
      })
      promise.then(data => {
        const resultArray = data.data
        const newEditArray = Object.keys(data.data)
        for (const key of newEditArray) {
          if (resultArray[key] === null) {
            resultArray[key] = ''
          }
        }
        setValue('roomsValues', resultArray, { shouldValidate: true })
      })
    },
    [ajax, setValue, idRooms]
  )

  //Request for add new Employer
  const onSubmit = data => {
    const body = { ...data.roomsValues, id: currentRoomsId, branch_id: idRooms }
    const sendButtonRooms = document.querySelector('.sendButtonRooms')
    if (sendButtonRooms.dataset.rooms === 'true') {
      const promise = new Promise(resolve => {
        ajax.request(body, 'manager/rooms/update', 'post', resolve)
      })
      promise.then(data => {
        const newArrayEmployers = roomsList.slice()
        const currentEditEmployer = newArrayEmployers.indexOf(newArrayEmployers.find(child => child.id === Number(body.id)))
        newArrayEmployers[currentEditEmployer] = body
        setRoomsList(newArrayEmployers)
        reset()
        sendButtonRooms.setAttribute('data-rooms', 'false')
      })
    } else {
      const body = { ...data.roomsValues, branch_id: idRooms }
      const promise = new Promise(resolve => {
        ajax.request(body, 'manager/rooms/add', 'post', resolve)
      })
      promise.then(data => {
        reset()
        const newArrayRooms = roomsList.slice()
        newArrayRooms.unshift(data.data)
        setRoomsList(newArrayRooms)
      })
    }
  }

  //Request for delete Employer
  const deleteEmployer = useCallback(
    event => {
      const body = {
        id: event.target.dataset.value,
        branch_id: event.target.closest('.item').dataset.branch,
      }
      const promise = new Promise(resolve => {
        ajax.request(body, 'manager/rooms/delete', 'post', resolve)
      })
      promise.then(data => {
        const newDeleteArray = roomsList.slice()
        const indexDelete = newDeleteArray.indexOf(newDeleteArray.find(child => child.id === Number(event.target.dataset.value)))
        newDeleteArray.splice(indexDelete, 1)
        setRoomsList(newDeleteArray)
      })
    },
    [roomsList, setRoomsList, ajax]
  )

  return (
    <Modal active={modalRooms} setActive={setModalRooms}>
      <section className='rooms'>
        <div className='roomsTable'>
          <div className='addNew'>
            <h2>{titleBranch}</h2>
            <button
              value='addNew'
              onClick={function () {
                reset()
                const sendButtonRooms = document.querySelector('.sendButtonRooms')
                sendButtonRooms.setAttribute('data-rooms', 'false')
              }}>
              Add
            </button>
          </div>
          <div className='currentTable'>
            <div className='item title'>
              <h2>Name</h2>
              <h2>Address</h2>
              <h2>Seats</h2>
              <h2>Tables</h2>
              <h2>Chairs</h2>
              <h2>Projector</h2>
              <h2>Description</h2>
              <div className='itemButtons'></div>
            </div>
            {roomsMain}
          </div>
        </div>
        <div className='roomsEdit'>
          <h2>Rooms info</h2>
          <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className='labelDiv'>
                <label>
                  <p>
                    Name <span className='requiredInput'>*</span>
                  </p>
                  <input
                    {...register('roomsValues.name', {
                      required: 'This field is required',
                      minLength: {
                        value: 3,
                        message: 'Min length 3',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Max length 50',
                      },
                    })}
                  />
                </label>
                <span className='errorResponse'>
                  {errors.hasOwnProperty('roomsValues') && errors.roomsValues.hasOwnProperty('name') ? errors.roomsValues.name.message : null}
                </span>
              </div>
              <div className='labelDiv'>
                <label>
                  <p>
                    Address <span className='requiredInput'>*</span>
                  </p>
                  <input
                    {...register('roomsValues.address', {
                      required: 'This field is required',
                      minLength: {
                        value: 3,
                        message: 'Min length 3',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Max length 50',
                      },
                    })}
                  />
                </label>
                <span className='errorResponse'>
                  {errors.hasOwnProperty('roomsValues') && errors.roomsValues.hasOwnProperty('address') ? errors.roomsValues.address.message : null}
                </span>
              </div>
            </div>
            <div>
              <div className='labelDiv'>
                <label>
                  Seats
                  <input
                    type='number'
                    {...register('roomsValues.seats', {
                      maxLength: {
                        value: 100,
                        message: 'Max length 50',
                      },
                    })}
                  />
                </label>
                <span className='errorResponse'>
                  {errors.hasOwnProperty('roomsValues') && errors.roomsValues.hasOwnProperty('seats') ? errors.roomsValues.seats.message : null}
                </span>
              </div>
              <div className='labelDiv'>
                <label>
                  Tables
                  <input
                    type='number'
                    {...register('roomsValues.tables', {
                      maxLength: {
                        value: 100,
                        message: 'Max length 50',
                      },
                    })}
                  />
                </label>
                <span className='errorResponse'>
                  {errors.hasOwnProperty('roomsValues') && errors.roomsValues.hasOwnProperty('tables') ? errors.roomsValues.tables.message : null}
                </span>
              </div>
            </div>
            <label>
              <p>Chairs</p>
              <input
                {...register('roomsValues.chairs', {
                  maxLength: {
                    value: 100,
                    message: 'Max length 50',
                  },
                })}
              />
            </label>
            <span className='errorResponse'>
              {errors.hasOwnProperty('roomsValues') && errors.roomsValues.hasOwnProperty('chairs') ? errors.roomsValues.chairs.message : null}
            </span>
            <label>
              Projector
              <select
                {...register('roomsValues.projector', {
                  required: 'This field is required',
                })}>
                <option value='0'>No</option>
                <option value='1'>Yes</option>
              </select>
            </label>
            <span className='errorResponse'>
              {errors.hasOwnProperty('roomsValues') && errors.roomsValues.hasOwnProperty('projector') ? errors.roomsValues.projector.message : null}
            </span>
            <label>
              Description
              <textarea
                {...register('roomsValues.description', {
                  maxLength: {
                    value: 500,
                    message: 'Max length 500',
                  },
                })}
              />
            </label>
            <span className='errorResponse'>
              {errors.hasOwnProperty('roomsValues') && errors.roomsValues.hasOwnProperty('description')
                ? errors.roomsValues.description.message
                : null}
            </span>
            <div className='buttons'>
              <input type='submit' className='sendButton sendButtonRooms' value='Save' disabled={!isValid} />
              <span className='sendButton' onClick={() => setModalRooms(false)}>
                Cancel
              </span>
            </div>
          </form>
        </div>
      </section>
    </Modal>
  )
}
