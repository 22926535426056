import Modal from '../modal'
import Search from '../search'
import Loading from '../loading'
import Pagination from '../pagination'
import useAjax from '../../hooks/useAjax'
import { useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { curriculumAdd } from '../../store/slices/education'

import '../../styles/education.scss'

export default function Curriculum() {
  //Initialization state manager
  const ajax = useAjax()
  const dispatch = useDispatch()
  const [contentPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false)
  const [staffList, setStaffList] = useState('')
  const [currentId, setCurrentId] = useState('')
  const [pagination, setPagination] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [modalButton, setModalButton] = useState(null)
  const [currentRequest, setCurrentRequest] = useState('')
  const allCurriculum = useSelector(state => state.educationReducer.curriculum)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      staffValues: {
        name: '',
        version: '',
        accompanying_factor: '',
        permissive_factor: '',
      },
    },
    reValidateMode: 'onChange',
  })

  //Request for delete information from table
  const deleteStaff = useCallback(
    event => {
      const promise = new Promise(resolve => {
        ajax.request({ id: event.target.dataset.id }, 'manager/educationPlans/delete', 'post', resolve)
      })
      promise.then(data => {
        const deleteArray = allCurriculum.slice()
        const indexDelete = deleteArray.indexOf(deleteArray.find(child => child.id === Number(event.target.dataset.id)))
        deleteArray.splice(indexDelete, 1)
        dispatch(curriculumAdd(deleteArray))
        setModal(false)
      })
    },
    [dispatch, allCurriculum]
  )

  //Function for open modal edit or add
  const openModal = useCallback(
    event => {
      if (event.target.value === 'addNew') {
        reset()
        setCurrentRequest('add')
        setModalButton(
          <span className='sendButton' onClick={() => setModal(false)}>
            Cancel
          </span>
        )
        setModal(true)
      } else {
        setCurrentRequest('edit')
        const id = event.target.closest('.item').dataset.value
        setModalButton(
          <span className='sendButton deleteButton' data-id={id} onClick={event => deleteStaff(event)}>
            Delete
          </span>
        )
        setCurrentId(id)
        const promise = new Promise(resolve => {
          ajax.request({ id }, 'manager/educationPlans/getOne', 'post', resolve)
        })
        promise.then(data => {
          setValue('staffValues', data.data, { shouldValidate: true })
          setModal(true)
        })
      }
    },
    [reset, setValue, deleteStaff]
  )

  //Get all request
  useEffect(() => {
    if (allCurriculum === false) {
      const promise = new Promise(resolve => {
        ajax.request(null, 'manager/educationPlans/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(curriculumAdd(newArr))
      })
    }
  }, [allCurriculum, dispatch])

  //Function for paste in table
  useEffect(() => {
    if (allCurriculum) {
      const lastPageIndex = currentPage * contentPerPage
      const firstPageIndex = lastPageIndex - contentPerPage
      const currentMyPage = allCurriculum
        .filter(
          element =>
            element.name.toLowerCase().includes(search.toLowerCase()) +
            element.version.toLowerCase().includes(search.toLowerCase()) +
            element.accompanying_factor.toLowerCase().includes(search.toLowerCase()) +
            element.permissive_factor.toLowerCase().includes(search.toLowerCase())
        )
        .slice(firstPageIndex, lastPageIndex)
      if (currentMyPage.length === 0) {
        setStaffList(<p className='nothingFound'>Nothing found</p>)
      } else {
        setStaffList(
          Object.values(currentMyPage).map(child => {
            return (
              <div className='item mainItem' key={child.id} data-value={child.id} onClick={event => openModal(event)}>
                <p title={child.name} data-value={child.id}>
                  {child.name}
                </p>
                <p title={child.version} data-value={child.id} className='version'>
                  {child.version}
                </p>
                <p title={child.accompanying_factor} data-value={child.id}>
                  {child.accompanying_factor}
                </p>
                <p title={child.permissive_factor} data-value={child.id}>
                  {child.permissive_factor}
                </p>
              </div>
            )
          })
        )
        setPagination(
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={search}
            allItems={allCurriculum}
            contentPerPage={contentPerPage}
            name={'name'}
          />
        )
      }
    } else {
      setStaffList(<Loading />)
      setPagination(null)
    }
  }, [allCurriculum, search, currentPage, contentPerPage, openModal])

  const onSubmit = data => {
    if (currentRequest === 'add') {
      const promise = new Promise(resolve => {
        ajax.request(data.staffValues, 'manager/educationPlans/add', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allCurriculum.slice()
        newArray.unshift(data.data)
        dispatch(curriculumAdd(newArray))
        reset()
        setModal(false)
      })
    } else {
      const body = { ...data.staffValues, id: Number(currentId) }
      const promise = new Promise(resolve => {
        ajax.request(body, 'manager/educationPlans/update', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allCurriculum.slice()
        const currentEdit = newArray.indexOf(newArray.find(child => child.id === Number(currentId)))
        newArray[currentEdit] = body
        dispatch(curriculumAdd(newArray))
        setModal(false)
        reset()
      })
    }
  }

  return (
    <section className='staff'>
      <div className='addNew'>
        <h2>Curriculum</h2>
        <Search search={search} setSearch={setSearch} setCurrentPage={setCurrentPage} />
        <button value='addNew' onClick={event => openModal(event)}>
          Add
        </button>
      </div>
      <div className='table'>
        <div className='item title'>
          <h2>Plan name</h2>
          <h2 className='version'>Version</h2>
          <h2>The accompanying factor</h2>
          <h2>The permissive factor</h2>
        </div>
        {staffList}
      </div>
      {pagination}
      <Modal active={modal} setActive={setModal}>
        <h2>Curriculum info</h2>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <label>
            <p>
              Plan name <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('staffValues.name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('name') ? errors.staffValues.name.message : null}
          </span>
          <label>
            <p>
              Version <span className='requiredInput'>*</span>
            </p>
            <input
              type='number'
              {...register('staffValues.version', {
                required: 'This field is required',
                minLength: {
                  value: 4,
                  message: 'Min length 4',
                },
                maxLength: {
                  value: 4,
                  message: 'Max length 4',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('version') ? errors.staffValues.version.message : null}
          </span>
          <label>
            <p>The accompanying factor</p>
            <input
              {...register('staffValues.accompanying_factor', {
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('accompanying_factor')
              ? errors.staffValues.accompanying_factor.message
              : null}
          </span>
          <label>
            <p>The permissive factor</p>
            <input
              {...register('staffValues.permissive_factor', {
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('permissive_factor')
              ? errors.staffValues.permissive_factor.message
              : null}
          </span>
          <div className='buttons'>
            <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
            {modalButton}
          </div>
        </form>
      </Modal>
    </section>
  )
}
