import Modal from '../modal'
import Search from '../search'
import Loading from '../loading'
import Pagination from '../pagination'
import { useForm } from 'react-hook-form'
import useAjax from '../../hooks/useAjax'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cycleAdd } from '../../store/slices/education'

export default function Enrollment() {
  //Initialization state manager
  const ajax = useAjax()
  const dispatch = useDispatch()
  const [contentPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(false)
  const [staffList, setStaffList] = useState('')
  const [modalTwo, setModalTwo] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [pagination, setPagination] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [currentForm, setCurrentForm] = useState(null)
  const allCycle = useSelector(state => state.educationReducer.cycle)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      additionalData: {
        branch: '',
        openDate: '',
        status: '',
      },
    },
    reValidateMode: 'onChange',
  })

  //Function for open modal in table
  const openModal = event => {
    if (event.target.value === 'course') {
      setModal(true)
    } else {
      setModalTwo(true)
    }
  }

  //Get all request
  useEffect(() => {
    if (allCycle === false) {
      const promise = new Promise(resolve => {
        ajax.request(null, 'manager/educationCicles/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(cycleAdd(newArr))
      })
    }
  }, [allCycle, dispatch])

  //Function for paste in table
  useEffect(() => {
    if (allCycle) {
      const lastPageIndex = currentPage * contentPerPage
      const firstPageIndex = lastPageIndex - contentPerPage
      const currentMyPage = allCycle
        .filter(
          element =>
            element.branch_name.toLowerCase().includes(search.toLowerCase()) +
            element.education_plan_name.toLowerCase().includes(search.toLowerCase())
        )
        .slice(firstPageIndex, lastPageIndex)
      if (currentMyPage.length === 0) {
        setStaffList(<p className='nothingFound'>Nothing found</p>)
      } else {
        setStaffList(
          Object.values(currentMyPage).map(child => {
            return (
              <div className='item mainItem' key={child.id} data-value={child.id} onClick={event => openModal(event)}>
                <p title={child.education_plan_name} data-value={child.id}>
                  {child.education_plan_name}
                </p>
                <p title={child.branch_name} data-value={child.id}>
                  {child.branch_name}
                </p>
                <p title={child.opening_date} data-value={child.id}>
                  {child.opening_date}
                </p>
                <p title={child.education_start} data-value={child.id}>
                  {child.education_start}
                </p>
                <p title={child.education_end} data-value={child.id}>
                  {child.education_end}
                </p>
                <p title={child.course_of_study} data-value={child.id}>
                  {child.course_of_study}
                </p>
              </div>
            )
          })
        )
        setPagination(
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={search}
            allItems={allCycle}
            contentPerPage={contentPerPage}
            name={'education_plan_name'}
          />
        )
      }
    } else {
      setStaffList(<Loading />)
      setPagination(null)
    }
  }, [allCycle, search, currentPage, contentPerPage])

  //Request for add new
  const onSubmit = data => {}

  return (
    <section className='additional'>
      <h2>Opened courses</h2>
      <div className='mainTable'>
        <div className='table'>
          <div className='nameItem'>
            <h3>Name course</h3>
          </div>
          <div className='tableScroll'>
            <p>Branch: dsadas</p>
            <p>Open date: 22/12/2222</p>
            <p>Status: active</p>
          </div>
          <button value='course' onClick={event => openModal(event)}>
            Enroll to the course
          </button>
          <button value='meeting' onClick={event => openModal(event)}>
            Create a meeting
          </button>
        </div>
      </div>
      <Modal active={modal} setActive={setModal}>
        <div className='mainCycle'>
          <div className='cycleModal'>
            <h2>Enroll to the course</h2>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
              <label>
                <p>
                  Opening date <span className='requiredInput'>*</span>
                </p>
                <input
                  type='number'
                  {...register('staffValues.opening_date', {
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'Min length 3',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Max length 50',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('opening_date')
                  ? errors.staffValues.opening_date.message
                  : null}
              </span>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>
                      Education start <span className='requiredInput'>*</span>
                    </p>
                    <input
                      type='number'
                      {...register('staffValues.education_start', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Min length 3',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('education_start')
                      ? errors.staffValues.education_start.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>
                      Education end <span className='requiredInput'>*</span>
                    </p>
                    <input
                      type='number'
                      {...register('staffValues.education_end', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Min length 3',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('education_end')
                      ? errors.staffValues.education_end.message
                      : null}
                  </span>
                </div>
              </div>
              <label>
                <p>
                  Course of study <span className='requiredInput'>*</span>
                </p>
                <input
                  type='number'
                  {...register('staffValues.course_of_study', {
                    required: 'This field is required',
                    minLength: {
                      value: 3,
                      message: 'Min length 3',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Max length 50',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('course_of_study')
                  ? errors.staffValues.course_of_study.message
                  : null}
              </span>
            </form>
          </div>
          <div className='cycleStudents'>
            <div className='searchMain'>
              <Search search={search} setSearch={setSearch} setCurrentPage={setCurrentPage} />
              <button className='enrollment'>Enrollment</button>
            </div>
            <div className='table'>
              <div className='item title'>
                <h2>Plan name</h2>
                <h2>Branch name</h2>
                <h2>Opening date</h2>
                <h2>Education start</h2>
              </div>
              {staffList}
            </div>
          </div>
        </div>
        <div className='buttons'>
          <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
          <span className='sendButton' onClick={() => setModal(false)}>
            Cancel
          </span>
        </div>
      </Modal>
      <Modal active={modalTwo} setActive={setModalTwo}>
        <h3>Create a meeting</h3>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <label>
            <p>
              Meeting name <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.meeting_name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('meeting_name')
              ? errors.additionalData.meeting_name.message
              : null}
          </span>
          <label>
            <p>
              Course name <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.course_name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('course_name')
              ? errors.additionalData.course_name.message
              : null}
          </span>
          <label>
            <p>
              Meeting date <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.meeting_date', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('meeting_date')
              ? errors.additionalData.meeting_date.message
              : null}
          </span>
          <label>
            <p>
              Planned time of beginning <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.planned_time_of_beginning', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('planned_time_of_beginning')
              ? errors.additionalData.planned_time_of_beginning.message
              : null}
          </span>
          <label>
            <p>
              Ending time <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.ending_time', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('ending_time')
              ? errors.additionalData.ending_time.message
              : null}
          </span>
          <label>
            <p>
              Teacher name<span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.teacher_name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('teacher_name')
              ? errors.additionalData.teacher_name.message
              : null}
          </span>
          <label>
            <p>
              Room name<span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.room_name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('room_name')
              ? errors.additionalData.room_name.message
              : null}
          </span>
          <label>
            <p>
              Additional materials<span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.additional_materials', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('additional_materials')
              ? errors.additionalData.additional_materials.message
              : null}
          </span>
          <div className='buttons'>
            <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
            <span className='sendButton' onClick={() => setModal(false)}>
              Cancel
            </span>
          </div>
        </form>
      </Modal>
    </section>
  )
}
