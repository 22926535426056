import useAjax from '../hooks/useAjax'
import Modal from '../components/modal'
import { useForm } from 'react-hook-form'
import Search from '../components/search'
import Loading from '../components/loading'
import { useTranslation } from 'react-i18next'
import Pagination from '../components/pagination'
import { studentsAdd } from '../store/slices/students'
import { useDispatch, useSelector } from 'react-redux'
import { branchesAdd } from '../store/slices/branches'
import React, { useCallback, useEffect, useState } from 'react'
import { requestGetAll as statuses } from '../store/slices/additional/statuses'
import { requestGetAll as exemptions } from '../store/slices/additional/exemptions'
import { requestGetAll as statusPlacement } from '../store/slices/additional/statusPlacement'
import { requestGetAll as exemptionsStatus } from '../store/slices/additional/exemptionsStatus'

import defaultImage from '../images/default.png'

import '../styles/students.scss'

export default function Students() {
  //Translate initialization
  const { t } = useTranslation()
  document.title = 'Students | ' + t('name')

  //State manager initialization
  const ajax = useAjax().request
  const dispatch = useDispatch()
  const setDefaultImage = defaultImage
  const [contentPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [modal, setModal] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [deleted, setDeleted] = useState(0)
  const [staffList, setStaffList] = useState('')
  const [currentId, setCurrentId] = useState(null)
  const [pagination, setPagination] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [modalButton, setModalButton] = useState(null)
  const [currentRequest, setCurrentRequest] = useState('')
  const [studentPhoto, setStudentPhoto] = useState(defaultImage)
  const [currentModalBlock, setCurrentModalBlock] = useState('general')
  const allBranches = useSelector(state => state.branchesReducer.branches)
  const allStudents = useSelector(state => state.studentsReducer.students)
  const allStatuses = useSelector(state => state.statusesReducer.statuses)
  const allExemptions = useSelector(state => state.exemptionsReducer.exemptions)
  const allStatusPlacement = useSelector(state => state.statusPlacementReducer.statusPlacement)
  const allExemptionsStatus = useSelector(state => state.exemptionsStatusReducer.exemptionsStatus)

  //Initialization form in modal
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      staffValues: {
        name: '',
        work: '',
        duty: '',
        city: '',
        notes: '',
        phone: '',
        email: '',
        index: '',
        mobile: '',
        gender: '',
        address: '',
        id_number: '',
        last_name: '',
        branch_id: '',
        status_id: '',
        birth_date: '',
        branch_name: '',
        placement_id: '',
        exemption_id: '',
        exemption_status_id: '',
        demobilized_soldier: '',
        placement_update_at: '',
        ultra_orthodox_project: '',
        availability_placement_department: '',
      },
    },
    reValidateMode: 'onChange',
  })

  //Request for all status
  const brachList = allBranches
    ? Object.values(allBranches).map(child => {
        return (
          <option key={child.id} value={child.id}>
            {child.name}
          </option>
        )
      })
    : null

  useEffect(() => {
    if (!allBranches) {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/branches/getAll', 'post', resolve)
      })
      promise.then(data => {
        dispatch(branchesAdd(data.data))
      })
    }
  }, [allBranches, ajax, dispatch])

  //Request for all branches
  const statusesSelect = allStatuses
    ? Object.values(allStatuses).map(child => {
        return (
          <option key={child.id} value={child.id}>
            {child.name}
          </option>
        )
      })
    : null

  useEffect(() => {
    if (!allStatuses) {
      dispatch(statuses())
    }
  }, [allStatuses, ajax, dispatch])

  //Request for all exemption select
  const exemptionSelect = allExemptions
    ? Object.values(allExemptions).map(child => {
        return (
          <option key={child.id} value={child.id}>
            {child.name}
          </option>
        )
      })
    : null

  useEffect(() => {
    if (!allExemptions) {
      dispatch(exemptions())
    }
  }, [allExemptions, dispatch])

  //Request for all exemption status select
  const exemptionStatusSelect = allExemptionsStatus
    ? Object.values(allExemptionsStatus).map(child => {
        return (
          <option key={child.id} value={child.id}>
            {child.name}
          </option>
        )
      })
    : null

  useEffect(() => {
    if (!allExemptionsStatus) {
      dispatch(exemptionsStatus())
    }
  }, [allExemptionsStatus, dispatch])

  //Request for all placement select
  const placementSelect = allStatusPlacement
    ? Object.values(allStatusPlacement).map(child => {
        return (
          <option key={child.id} value={child.id}>
            {child.name}
          </option>
        )
      })
    : null

  useEffect(() => {
    if (!allStatusPlacement) {
      dispatch(statusPlacement())
    }
  }, [allStatusPlacement, dispatch])

  //Request for all students
  useEffect(() => {
    if (!allStudents) {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/students/getAll', 'post', resolve)
      })
      promise.then(data => {
        const newArr = data.data.map(item => {
          const tempObj = {}
          for (let key in item) {
            if (item[key] === null) {
              tempObj[key] = ''
              continue
            }
            tempObj[key] = item[key]
          }
          return tempObj
        })
        dispatch(studentsAdd(newArr))
      })
    }
  }, [allStudents, ajax, dispatch])

  //Function for delete from table
  const onDelete = useCallback(
    event => {
      const promise = new Promise(resolve => {
        ajax({ id: event.target.dataset.id }, 'manager/students/delete', 'post', resolve)
      })
      promise.then(() => {
        const newDeleteArray = allStudents.slice()
        const indexDelete = newDeleteArray.indexOf(newDeleteArray.find(child => child.id === Number(event.target.dataset.id)))
        newDeleteArray.splice(indexDelete, 1)
        dispatch(studentsAdd(newDeleteArray))
        setPhoto(null)
        reset()
        setModal(false)
      })
    },
    [allStudents, dispatch, reset, ajax]
  )

  //Function for open modal in table
  const openModal = useCallback(
    event => {
      if (event.target.value === 'addNew') {
        setCurrentRequest('add')
        reset()
        setModalButton(
          <span className='sendButton' onClick={() => setModal(false)}>
            Cancel
          </span>
        )
        setStudentPhoto(defaultImage)
        setDeleted(0)
        setModal(true)
      } else {
        const promise = new Promise(resolve => {
          ajax({ id: event.target.dataset.value }, 'manager/students/getOne', 'post', resolve)
        })
        promise.then(data => {
          if (data.data.photo) {
            setStudentPhoto(data.data.photo)
          }
          setModalButton(
            <span className='sendButton deleteButton' data-id={event.target.dataset.value} onClick={event => onDelete(event)}>
              Delete
            </span>
          )
          setCurrentId(event.target.dataset.value)
          setDeleted(data.data.is_photo)
          const newArr = data.data
          for (let key in newArr) {
            if (newArr[key] === null) {
              newArr[key] = ''
            }
          }
          setValue('staffValues', newArr, { shouldValidate: true })
          setCurrentRequest('update')
          setModal(true)
        })
      }
    },
    [onDelete, reset, setValue, ajax]
  )

  //Function for paste information in HTML
  useEffect(() => {
    if (allStudents) {
      const lastPageIndex = currentPage * contentPerPage
      const firstPageIndex = lastPageIndex - contentPerPage
      const currentMyPage = allStudents
        .filter(
          element =>
            element.name.toLowerCase().includes(search.toLowerCase()) +
            element.last_name.toLowerCase().includes(search.toLowerCase()) +
            element.mobile.toLowerCase().includes(search.toLowerCase()) +
            element.id_number.toLowerCase().includes(search.toLowerCase())
        )
        .slice(firstPageIndex, lastPageIndex)
      if (currentMyPage.length === 0) {
        setStaffList(<p className='nothingFound'>Nothing found</p>)
      } else {
        setStaffList(
          Object.values(currentMyPage).map(child => {
            return (
              <div className='item mainItem' key={child.id} data-value={child.id} onClick={event => openModal(event)}>
                <p title={child.name} data-value={child.id}>
                  {child.name}
                </p>
                <p title={child.last_name} data-value={child.id}>
                  {child.last_name}
                </p>
                <p title={child.phone} data-value={child.id}>
                  {child.phone}
                </p>
                <p title={child.id_number} data-value={child.id}>
                  {child.id_number}
                </p>
                <p title={child.branch_name} data-value={child.id}>
                  {child.branch_name}
                </p>
              </div>
            )
          })
        )
        setPagination(
          <Pagination
            name={'name'}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            search={search}
            allItems={allStudents}
            contentPerPage={contentPerPage}
          />
        )
      }
    } else {
      setStaffList(<Loading />)
      setPagination(null)
    }
  }, [allStudents, search, contentPerPage, currentPage, openModal])

  //Request for add or edit information
  const onSubmit = data => {
    if (currentRequest === 'add') {
      const formData = new FormData()
      const tempObj = { ...data.staffValues, password: '123456', password_confirmation: '123456', photo: photo, is_photo: deleted }
      for (let key in tempObj) {
        formData.append(key, tempObj[key])
      }
      const promise = new Promise(resolve => {
        ajax(formData, 'manager/students/add', 'post', resolve)
      })
      promise.then(data => {
        const newArray = allStudents.slice()
        newArray.unshift(data.data)
        dispatch(studentsAdd(newArray))
        setPhoto(null)
        reset()
        setModal(false)
      })
    } else {
      const formData = new FormData()
      const tempObj = { ...data.staffValues, id: currentId, password: '123456', password_confirmation: '123456', photo: photo, is_photo: deleted }
      for (let key in tempObj) {
        formData.append(key, tempObj[key])
      }
      const promise = new Promise(resolve => {
        ajax(formData, 'manager/students/update', 'post', resolve)
      })
      promise.then(() => {
        const newArray = allStudents.slice()
        const currentEdit = newArray.indexOf(newArray.find(child => child.id === Number(currentId)))
        newArray[currentEdit] = tempObj
        dispatch(studentsAdd(newArray))
        setPhoto(null)
        reset()
        setModal(false)
      })
    }
  }

  return (
    <section className='staff'>
      <div className='addNew'>
        <h2>Students</h2>
        <Search search={search} setSearch={setSearch} setCurrentPage={setCurrentPage} />
        <button
          value='addNew'
          onClick={event => {
            openModal(event)
          }}>
          Add
        </button>
      </div>
      <div className='table'>
        <div className='item title'>
          <h2>Name</h2>
          <h2>Surname</h2>
          <h2>Phone number</h2>
          <h2>ID</h2>
          <h2>Branch name</h2>
        </div>
        {staffList}
      </div>
      {pagination}
      <Modal active={modal} setActive={setModal} className='studentModal'>
        <h2>Students info</h2>
        <div className='titleButton'>
          <button className={currentModalBlock === 'general' ? 'active' : null} onClick={() => setCurrentModalBlock('general')}>
            General
          </button>
          <button className={currentModalBlock === 'additional' ? 'active' : null} onClick={() => setCurrentModalBlock('additional')}>
            Additional data
          </button>
          <button className={currentModalBlock === 'notes' ? 'active' : null} onClick={() => setCurrentModalBlock('notes')}>
            Notes
          </button>
          <button className={currentModalBlock === 'history' ? 'active' : null} onClick={() => setCurrentModalBlock('history')}>
            History
          </button>
          <button className={currentModalBlock === 'payment' ? 'active' : null} onClick={() => setCurrentModalBlock('payment')}>
            Payment info
          </button>
        </div>
        <div className='modalImageBlock'>
          <div className='firstDiv'>
            <img src={studentPhoto} alt='user' />
            <span
              onClick={() => {
                setPhoto(null)
                setStudentPhoto(setDefaultImage)
                setDeleted(1)
              }}>
              x
            </span>
            <label>
              <p className='button'>{photo ? photo.name : 'Add image'}</p>
              <input
                type='file'
                onChange={event => {
                  setPhoto(event.target.files[0])
                }}
              />
            </label>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
            <div className={currentModalBlock === 'general' ? 'currentModal' : 'none'}>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>
                      Name <span className='requiredInput'>*</span>
                    </p>
                    <input
                      {...register('staffValues.name', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Min length 3',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('name') ? errors.staffValues.name.message : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>
                      Surname <span className='requiredInput'>*</span>
                    </p>
                    <input
                      {...register('staffValues.last_name', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 100',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('last_name')
                      ? errors.staffValues.last_name.message
                      : null}
                  </span>
                </div>
              </div>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>Phone</p>
                    <input
                      type='number'
                      {...register('staffValues.phone', {
                        minLength: {
                          value: 6,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max length 100',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('phone') ? errors.staffValues.phone.message : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>
                      Mob phone <span className='requiredInput'>*</span>
                    </p>
                    <input
                      type='number'
                      {...register('staffValues.mobile', {
                        required: 'This field is required',
                        minLength: {
                          value: 6,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 100,
                          message: 'Max length 100',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('mobile') ? errors.staffValues.mobile.message : null}
                  </span>
                </div>
              </div>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>
                      ID <span className='requiredInput'>*</span>
                    </p>
                    <input
                      type='number'
                      {...register('staffValues.id_number', {
                        required: 'This field is required',
                        minLength: {
                          value: 6,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('id_number')
                      ? errors.staffValues.id_number.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Birth day</p>
                    <input
                      {...register('staffValues.birth_date', {
                        minLength: {
                          value: 6,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('birth_date')
                      ? errors.staffValues.birth_date.message
                      : null}
                  </span>
                </div>
              </div>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>City </p>
                    <input
                      {...register('staffValues.city', {
                        minLength: {
                          value: 6,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('city') ? errors.staffValues.city.message : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Index</p>
                    <input
                      {...register('staffValues.index', {
                        minLength: {
                          value: 4,
                          message: 'Min length 4',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('index') ? errors.staffValues.index.message : null}
                  </span>
                </div>
              </div>
              <label>
                <p>Gender</p>
                <select {...register('staffValues.gender', {})}>
                  <option value='1'>Man</option>
                  <option value='0'>Women</option>
                </select>
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('gender') ? errors.staffValues.gender.message : null}
              </span>
              <label>
                <p>Address </p>
                <input
                  {...register('staffValues.address', {
                    minLength: {
                      value: 6,
                      message: 'Min length 6',
                    },
                    maxLength: {
                      value: 50,
                      message: 'Max length 50',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('address') ? errors.staffValues.address.message : null}
              </span>
              <label>
                <p>
                  Email <span className='requiredInput'>*</span>
                </p>
                <input
                  {...register('staffValues.email', {
                    required: 'This field is required',
                    minLength: {
                      value: 6,
                      message: 'Min length 6',
                    },
                    maxLength: {
                      value: 100,
                      message: 'Max length 100',
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('email') ? errors.staffValues.email.message : null}
              </span>
            </div>
            <div className={currentModalBlock === 'additional' ? 'currentModal' : 'none'}>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>Branch name</p>
                    <select {...register('staffValues.branch_id', {})}>{brachList}</select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('branch_id')
                      ? errors.staffValues.branch_id.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Status code</p>
                    <select {...register('staffValues.status_id', {})}>{statusesSelect}</select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('status_id')
                      ? errors.staffValues.status_id.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Exemption code</p>
                    <select {...register('staffValues.exemption_id', {})}>{exemptionSelect}</select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('exemption_id')
                      ? errors.staffValues.exemption_id.message
                      : null}
                  </span>
                </div>
              </div>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>Exemption status</p>
                    <select {...register('staffValues.exemption_status_id', {})}>{exemptionStatusSelect}</select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('exemption_status_id')
                      ? errors.staffValues.exemption_status_id.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Placement status</p>
                    <select {...register('staffValues.placement_id', {})}>{placementSelect}</select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('placement_id')
                      ? errors.staffValues.placement_id.message
                      : null}
                  </span>
                </div>
              </div>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>Date of Placement status update</p>
                    <input readOnly='readonly' {...register('staffValues.placement_update_at', {})} />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('placement_update_at')
                      ? errors.staffValues.placement_update_at.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Availability for the placement department</p>
                    <select {...register('staffValues.availability_placement_department', {})}>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('availability_placement_department')
                      ? errors.staffValues.availability_placement_department.message
                      : null}
                  </span>
                </div>
              </div>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>Work</p>
                    <input
                      {...register('staffValues.work', {
                        minLength: {
                          value: 6,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('work') ? errors.staffValues.work.message : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Duty</p>
                    <input
                      {...register('staffValues.duty', {
                        minLength: {
                          value: 6,
                          message: 'Min length 6',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Max length 50',
                        },
                      })}
                    />
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('duty') ? errors.staffValues.duty.message : null}
                  </span>
                </div>
              </div>
              <div className='labelDiv'>
                <div>
                  <label>
                    <p>Budgeting of a demobilized soldier</p>
                    <select {...register('staffValues.demobilized_soldier', {})}>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('demobilized_soldier')
                      ? errors.staffValues.demobilized_soldier.message
                      : null}
                  </span>
                </div>
                <div>
                  <label>
                    <p>Budget of an active ultra-orthodox</p>
                    <select {...register('staffValues.ultra_orthodox_project', {})}>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </label>
                  <span className='errorResponse'>
                    {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('ultra_orthodox_project')
                      ? errors.staffValues.ultra_orthodox_project.message
                      : null}
                  </span>
                </div>
              </div>
            </div>
            <div className={currentModalBlock === 'notes' ? 'currentModal' : 'none'}>
              <label>
                <p>Notes</p>
                <textarea
                  {...register('staffValues.notes', {
                    minLength: {
                      value: 6,
                      message: 'Min length 6',
                    },
                    maxLength: {
                      value: 250,
                      message: 'Max length 250',
                    },
                  })}
                />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('staffValues') && errors.staffValues.hasOwnProperty('notes') ? errors.staffValues.notes.message : null}
              </span>
            </div>
            <div className={currentModalBlock === 'history' ? 'currentModal' : 'none'}></div>
            <div className={currentModalBlock === 'payment' ? 'currentModal' : 'none'}></div>
            <div className='buttons'>
              <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
              {modalButton}
            </div>
          </form>
        </div>
      </Modal>
    </section>
  )
}
