import Modal from '../modal'
import Loading from '../loading'
import { useForm } from 'react-hook-form'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestGetAll, requestAdd, requestUpdate, requestDelete, requestGetOne } from '../../store/slices/additional/employerStatus'

export default function StatusPlacement() {
  //Initialization state manager
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [currentForm, setCurrentForm] = useState(null)
  const allExemptions = useSelector(state => state.employerStatusReducer.tableInfo)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange', defaultValues: { additionalData: { name: '' } }, reValidateMode: 'onChange' })

  //Request for all items from table
  useEffect(() => {
    if (!allExemptions) {
      dispatch(requestGetAll())
    }
  }, [allExemptions, dispatch])

  //Request for add or edit item in table
  const onSubmit = data => {
    if (currentForm === 'add') {
      dispatch(requestAdd(data.additionalData))
      setModal(false)
    } else {
      dispatch(requestUpdate({ ...data.additionalData, id: currentId }))
      setModal(false)
    }
  }

  //Request for delete item from table
  const onDelete = event => {
    dispatch(requestDelete({ id: event.target.dataset.id }))
    setModal(false)
  }

  //Request for get one item from table
  const getOne = data => {
    setValue('additionalData', { name: data.name }, { shouldValidate: true })
    setModal(true)
  }

  //Function for detect current button and open corresponding modal window
  const openModal = event => {
    if (event.target.value === 'add') {
      setCurrentForm('add')
      reset()
      setModal(true)
    } else {
      setCurrentForm('edit')
      setCurrentId(event.target.closest('.item').dataset.id)
      dispatch(requestGetOne({ id: event.target.dataset.id, getOne }))
    }
  }

  return (
    <>
      <div className='table'>
        <div className='nameItem'>
          <h3>Employer status</h3>
          <button value='add' onClick={event => openModal(event)}>
            Add
          </button>
        </div>
        <div className='title'>
          <h4>Name</h4>
        </div>
        <div className='tableScroll'>
          {allExemptions ? (
            Object.values(allExemptions).map(child => {
              return (
                <div className='item' key={child.id} onClick={event => openModal(event)} data-id={child.id}>
                  <p title={child.name} data-id={child.id}>
                    {child.name}
                  </p>
                </div>
              )
            })
          ) : (
            <Loading />
          )}
        </div>
      </div>
      <Modal active={modal} setActive={setModal}>
        <h3>Employer status info</h3>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <label>
            <p>
              Name <span className='requiredInput'>*</span>
            </p>
            <input
              {...register('additionalData.name', {
                required: 'This field is required',
                minLength: {
                  value: 3,
                  message: 'Min length 3',
                },
                maxLength: {
                  value: 50,
                  message: 'Max length 50',
                },
              })}
            />
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('name') ? errors.additionalData.name.message : null}
          </span>

          <div className='buttons'>
            <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
            <span
              className='sendButton deleteButton'
              data-id={currentId}
              style={{ display: currentForm === 'edit' ? 'flex' : 'none' }}
              onClick={event => onDelete(event)}>
              Delete
            </span>
            <span className='sendButton' style={{ display: currentForm === 'add' ? 'flex' : 'none' }} onClick={() => setModal(false)}>
              Cancel
            </span>
          </div>
        </form>
      </Modal>
    </>
  )
}
