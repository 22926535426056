import Modal from '../modal'
import Loading from '../loading'
import { useForm } from 'react-hook-form'
import useAjax from '../../hooks/useAjax'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { branchesAdd } from '../../store/slices/branches'
import { coursesAdd } from '../../store/slices/courses'

export default function CourseOpening() {
  //Initialization state manager
  const ajax = useAjax().request
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [modalButton, setModalButton] = useState(null)
  const [currentForm, setCurrentForm] = useState(null)
  const allEmployerStatus = useSelector(state => state.coursesReducer.courses)
  const allBranches = useSelector(state => state.branchesReducer.branches)

  //Initialization form manager
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { additionalData: { branch_id: '', open_date: '', is_open: '', open_time: '' } },
    reValidateMode: 'onChange',
  })

  //Get all branches request
  useEffect(() => {
    if (!allBranches) {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/branches/getAll', 'post', resolve)
      })
      promise.then(data => {
        dispatch(branchesAdd(data.data))
      })
    }
  }, [allBranches, ajax, dispatch])

  const branches = allBranches
    ? allBranches.map(child => {
        return (
          <option key={child.id} value={child.id}>
            {child.name}
          </option>
        )
      })
    : null

  //Get all request
  useEffect(() => {
    if (!allEmployerStatus) {
      const promise = new Promise(resolve => {
        ajax(null, 'manager/courses/getAll', 'post', resolve)
      })
      promise.then(data => {
        dispatch(coursesAdd(data.data))
      })
    }
  }, [allEmployerStatus, ajax, dispatch])

  const tableBlock = allEmployerStatus ? (
    Object.values(allEmployerStatus).map(child => {
      return (
        <div className='table' key={child.id}>
          <div className='nameItem'>
            <h3>{child.name}</h3>
          </div>
          <div className='tableScroll'>
            <p>Branch: {child.branch_name}</p>
            <p>Open date: {child.open_date}</p>
            <p>Status: {child.is_open === 0 ? 'Closed' : 'Active'}</p>
          </div>
          <button value={child.id} onClick={event => openModal(event)}>
            Open the Course
          </button>
        </div>
      )
    })
  ) : (
    <Loading />
  )

  //Request for add or edit information in table
  const onSubmit = data => {
    const body = data.additionalData
    const promise = new Promise(resolve => {
      ajax({ ...body, id: Number(currentId) }, 'manager/courses/update', 'post', resolve)
    })
    promise.then(data => {
      const newArray = allEmployerStatus.slice()
      const currentEdit = newArray.indexOf(newArray.find(child => child.id === Number(currentId)))
      console.log(currentEdit)
      newArray[currentEdit] = { ...body, id: Number(currentId) }
      console.log(newArray)
      dispatch(coursesAdd(newArray))
      setModal(false)
    })
  }

  //Request for delete information from table
  const onDelete = event => {
    const promise = new Promise(resolve => {
      ajax({ id: event.target.dataset.id }, 'manager/courses/delete', 'post', resolve)
    })
    promise.then(data => {
      const deleteArray = allEmployerStatus.slice()
      const indexDelete = deleteArray.indexOf(deleteArray.find(child => child.id === Number(event.target.dataset.id)))
      deleteArray.splice(indexDelete, 1)
      dispatch(coursesAdd(deleteArray))
      setModal(false)
    })
  }

  //Function for open modal in table
  const openModal = event => {
    setCurrentId(event.target.value)
    const promise = new Promise(resolve => {
      ajax({ id: event.target.value }, 'manager/courses/getOne', 'post', resolve)
    })
    promise.then(data => {
      setValue('additionalData', data.data, { shouldValidate: true })
      setModal(true)
    })
  }

  return (
    <section className='additional'>
      <h2>Course opening</h2>
      <div className='mainTable'>{tableBlock}</div>
      <Modal active={modal} setActive={setModal}>
        <h3>Course opening info</h3>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <label>
            <p>
              Branch <span className='requiredInput'>*</span>
            </p>
            <select
              {...register('additionalData.branch_id', {
                required: 'This field is required',
              })}>
              {branches}
            </select>
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('branch_id')
              ? errors.additionalData.branch_id.message
              : null}
          </span>
          <div className='labelDiv'>
            <div>
              <label>
                <p>
                  Open time <span className='requiredInput'>*</span>
                </p>
                <input type='time' {...register('additionalData.open_time', {})} />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('open_time')
                  ? errors.additionalData.open_time.message
                  : null}
              </span>
            </div>
            <div>
              <label>
                <p>
                  Open date <span className='requiredInput'>*</span>
                </p>
                <input type='date' {...register('additionalData.open_date', {})} />
              </label>
              <span className='errorResponse'>
                {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('open_date')
                  ? errors.additionalData.open_date.message
                  : null}
              </span>
            </div>
          </div>
          <label>
            <p>
              Status <span className='requiredInput'>*</span>
            </p>
            <select
              {...register('additionalData.is_open', {
                required: 'This field is required',
              })}>
              <option value='0'>Closed</option>
              <option value='1'>Active</option>
            </select>
          </label>
          <span className='errorResponse'>
            {errors.hasOwnProperty('additionalData') && errors.additionalData.hasOwnProperty('is_open')
              ? errors.additionalData.is_open.message
              : null}
          </span>
          <div className='buttons'>
            <input type='submit' className='sendButton' value='Save' disabled={!isValid} />
            <span className='sendButton deleteButton' data-id={currentId} onClick={event => onDelete(event)}>
              Delete
            </span>
          </div>
        </form>
      </Modal>
    </section>
  )
}
