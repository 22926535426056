import React from 'react'

import '../styles/pagination.scss'

export default function Pagination({ setCurrentPage, currentPage, search, allItems, contentPerPage, name }) {
  return (
    <div className='pagination'>
      <button onClick={() => setCurrentPage(1)}>Start</button>
      <button onClick={() => setCurrentPage(currentPage - 1)} className={currentPage - 1 === 0 ? 'disable' : null}>
        {currentPage - 1}
      </button>
      <button className='active'>{currentPage}</button>
      <button
        onClick={() => setCurrentPage(currentPage + 1)}
        className={
          currentPage + 1 > Math.ceil(allItems.filter(element => element[name].toLowerCase().includes(search.toLowerCase())).length / contentPerPage)
            ? 'disable'
            : null
        }>
        {currentPage + 1}
      </button>
      <span
        className={
          currentPage + 10 > Math.ceil(allItems.filter(element => element[name].toLowerCase().includes(search.toLowerCase())).length / contentPerPage)
            ? 'disable'
            : null
        }>
        ...
      </span>
      <button
        onClick={() => setCurrentPage(currentPage + 10)}
        className={
          currentPage + 10 > Math.ceil(allItems.filter(element => element[name].toLowerCase().includes(search.toLowerCase())).length / contentPerPage)
            ? 'disable'
            : null
        }>
        {currentPage + 10}
      </button>
      <button
        onClick={() =>
          setCurrentPage(Math.ceil(allItems.filter(element => element[name].toLowerCase().includes(search.toLowerCase())).length / contentPerPage))
        }>
        Last
      </button>
    </div>
  )
}
