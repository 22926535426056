import useAjax from '../hooks/useAjax'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { userLogin } from '../store/slices/login'
import React, { useState, useEffect } from 'react'

import '../styles/header.scss'

import logo from '../images/new-logo.png'
import logoutIcon from '../images/icons/logout-btn.svg'
import emailIcon from '../images/icons/email.svg'
import mailIcon from '../images/icons/mail.svg'
import whatsApp from '../images/icons/whatsApp-i.svg'

export default function Header() {
  const ajax = useAjax()
  const dispatch = useDispatch()

  //Translate initialization
  const { i18n } = useTranslation()
  const changeLanguage = language => {
    i18n.changeLanguage(language)
  }

  //Initialization state manager
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString())

  //Request for logout in admin panel
  const logout = data => {
    const promise = new Promise((resolve, reject) => {
      ajax.request(null, 'logout', 'get', resolve, reject)
    })
    promise.then(data => {
      dispatch(userLogin(false))
      localStorage.removeItem('userToken')
      localStorage.removeItem('userLogin')
    })
    promise.catch(data => {
      dispatch(userLogin(false))
      localStorage.removeItem('userToken')
      localStorage.removeItem('userLogin')
    })
  }

  //Initialization change language button in header
  const currentLanguage =
    localStorage.i18nextLng === 'en' ? (
      <button onClick={() => changeLanguage('ru')} className='currentLanguage'>
        <span>RU</span>
      </button>
    ) : (
      <button onClick={() => changeLanguage('en')} className='currentLanguage'>
        <span>EN</span>
      </button>
    )

  //Initialization date timer for header menu
  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentTime(new Date().toLocaleString())
    }, 1000)
    return () => clearTimeout(timer)
  }, [currentTime])

  return (
    <header>
      <section>
        <div className='user'>
          <div>
            <img
              src='https://pbx25.x-cloud.info/stats/users-logo/12f6f06511d71ecbd8f075e371c675459f54d79e_20200318.gif'
              alt='ManagerImg'
              className='userIcon'
            />
            <p>Michael Three</p>
          </div>
          <div className='buttons'>
            <button>
              <img src={mailIcon} alt='mail icon' />
            </button>
            <button>
              <img src={whatsApp} alt='whatsApp icon' />
            </button>
            <button>
              <img src={emailIcon} alt='email icon' />
            </button>
          </div>
        </div>
        <div className='mainHeader'>
          <div className='time'>
            <img src={logo} alt='logo' />
            {currentTime}
            {currentLanguage}
            <button onClick={logout}>
              <img src={logoutIcon} alt='logout icon' />
            </button>
          </div>
          <div className='buttons'>
            <div>
              <span>
                <h2>10</h2>
                <p>Branches</p>
              </span>
              <span>
                <h2>5</h2>
                <p>Classes</p>
              </span>
            </div>
            <div>
              <span>
                <h2>100</h2>
                <p>Students</p>
              </span>
              <span>
                <h2>80</h2>
                <p>Active students </p>
              </span>
            </div>
            <div>
              <span>
                <h2>8</h2>
                <p>Active courses</p>
              </span>
              <span>
                <h2>3</h2>
                <p>Inactive courses</p>
              </span>
            </div>
            <div>
              <span>
                <h2>20</h2>
                <p>Reviews</p>
              </span>
              <span>
                <h2>19</h2>
                <p>Positive reviews </p>
              </span>
            </div>
          </div>
        </div>
      </section>
    </header>
  )
}
