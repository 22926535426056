import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const headers = { headers: { Authorization: `Bearer ${localStorage.userToken}`, 'Content-Type': 'application/json' } }
const url = 'https://api-learn.x-cloud.info/api/manager/placements-statuses/'

export const requestGetAll = createAsyncThunk('placements-statuses/requestGetAll', async () => {
  const response = await axios.post(url + 'getAll', null, headers)
  return response.data.data
})

export const requestAdd = createAsyncThunk('placements-statuses/requestAdd', async (data, { getState }) => {
  const response = await axios.post(url + 'add', data, headers)
  const result = getState().statusPlacementReducer.tableInfo.slice()
  result.unshift(response.data.data)
  return result
})

export const requestUpdate = createAsyncThunk('placements-statuses/requestUpdate', async (data, { getState }) => {
  await axios.post(url + 'update', data, headers)
  const result = getState().statusPlacementReducer.tableInfo.slice()
  const currentEdit = result.indexOf(result.find(child => child.id === Number(data.id)))
  result[currentEdit] = data
  return result
})

export const requestDelete = createAsyncThunk('placements-statuses/requestDelete', async (data, { getState }) => {
  await axios.post(url + 'delete', data, headers)
  const result = getState().statusPlacementReducer.tableInfo.slice()
  const indexDelete = result.indexOf(result.find(child => child.id === Number(data.id)))
  result.splice(indexDelete, 1)
  return result
})

export const requestGetOne = createAsyncThunk('placements-statuses/requestGetOne', async data => {
  const response = await axios.post(url + 'getOne', { id: data.id }, headers)
  data.getOne(response.data.data)
})

const statusPlacement = createSlice({
  name: 'statusPlacement',
  initialState: {
    tableInfo: null,
  },

  extraReducers: {
    [requestGetAll.fulfilled]: (state, action) => {
      state.tableInfo = action.payload
    },
    [requestAdd.fulfilled]: (state, action) => {
      state.tableInfo = action.payload
    },
    [requestUpdate.fulfilled]: (state, action) => {
      state.tableInfo = action.payload
    },
    [requestDelete.fulfilled]: (state, action) => {
      state.tableInfo = action.payload
    },
  },
})

export default statusPlacement.reducer
